.container {
    box-sizing: border-box;
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 50px 50px 20px 50px;
    width: 702px;
    left: calc(50% - 702px/2 - 0.5px);
    top: calc(50% - 500px/2 - 0.5px);
    background: #FFFFFF;
    border: 0.2px solid #87ABA9;
    border-radius: 10px;
}

.heading {
    position: relative;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    margin-top: 2px;
    margin-bottom: 10px;
    line-height: 38px;
    text-align: center;
    color: #181818;
}

.textContainer {
    padding: 0, 10%;
    text-overflow: break-word;
    margin-bottom: 3%;
    text-align: center;
}
.phonenumberfield {
    align-items: flex-start;
    padding: 23px 22px;
    gap: 10px;
    margin-top: 5%;
    margin-left: 2%;
    margin-right: 2%;
    position: relative;
    font-style: normal;
    font-weight: 550;
    font-size: 16px;
    line-height: 19px;
    width: 12%;
    height: 68px;
    background: rgba(0, 94, 96, 0.04);
    border-radius: 10px;
    border-color: rgba(0, 94, 96, 0.04);
    border: 0.2px solid rgba(0, 94, 96, 0.04);
    color: #005E60;
}

.phonenumberfield:focus {
    outline: 1.3px solid #87ABA9;
}

::placeholder {
    color: #828282;
    font-style: normal;
    font-weight: 550;
    font-size: 16px;
    line-height: 19px;
}

.textPrimary {
    color: #005E60 !important;
}

.textDanger {
    color: #FF0000 !important;
}

.textSuccess {
    color: #00FF00 !important;
}

.continue {
    align-items: center;
    gap: 20px;
    position: relative;
    height: 55px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    color: #005E60;
    cursor: pointer;
}

.continue:hover {
    background:#3F8484;
    color: #fff;
    border: 1px solid #3F8484;
}

.returnButton {
    align-items: center;
    gap: 20px;
    position: relative;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    color: #005E60;
    cursor: pointer;
}

.returnButton:hover {
    color: #3F8484;
    border: 1px solid #3F8484;
}

.returnContainer {
    margin-bottom: 20px;
}

.errormessage {
    color: #FF0000;
    position: relative;
    padding-top: 3%;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
}

.loadingbutton {
    margin-top: 12%;
    position: relative;
    display: flex;
    flex-direction: row;
    min-width: 95%;
    align-self: center;
    left: calc(50% - 108px/2 + 40.5px);
    --sk-color: #3F8484;
    --sk-size: 23px;
}

.formContainer {
    margin-bottom: 10%;
}

.captchaContainer {
    padding: 5%;
    margin: 5%;
    width: 100%;
    align-items: center;
    text-align: center;
    align-content: center;
}

@media only screen and (max-width: 916px) {
    

    .container {
        border: unset;
        top: calc(50% - 883px/2 - 0.5px);
        left: unset;
        top: unset;
        width: 100%;
        margin: 0;
        padding-left: 25px;
        padding-right: 25px;
    }


    .heading {
        position: relative;
        font-style: normal;
        font-weight: 700;
        width: 100%;
        font-size: 24px;
        margin-top: 10px;
        margin-bottom: 5px;
        line-height: 36px;
        text-align: center;
        color: #181818;
    }
    
    
    
    .phonenumberfield {
        align-items: center;
        font-size: 12px;
        padding: 23px 13px;
    }

    
    ::placeholder {
        font-size: 12px;

    }
    
    
    
    .continue {
        font-size: 12px;
    }
    
    

    
}