.container {
    position: absolute;
    height: 100%;
    width: 100%;
}

.eclipse1 {
    box-sizing: border-box;
    position: absolute;
    width: 911px;
    height: 85vh;
    left: -5px;
    top: -5px;
    background-image: url('../../assets/images/eclipse.svg');
    background-repeat: no-repeat;
}

.location {
    box-sizing: border-box;
    position: absolute;
    width: auto;
    height: 195px;
    left: 5%;
    right: 81.25%;
    top: 74.82%;
    bottom: 20.99%;
    background-image: url('../../assets/images/locationbackground.svg');
    background-repeat: no-repeat;
}

.eclipse2 {
    box-sizing: border-box;
    position: absolute;
    width: 22vw;
    height: 35vh;
    left: 78%;
    top: 65%;
    background-image: url('../../assets/images/eclipse2.svg');
    background-repeat: no-repeat;
}

@media only screen and (max-width: 916px) {
    .container {
        display: none;
    }
}

