.container {
    flex-direction: column;
    width: 99vw;
    padding: 12% 7% 15% 7%;
    display: flex;
    position: absolute;
    z-index: 1;
    background: white;
}

.top-menu {
    flex-direction: row;
    display: flex;
    position: relative;
}

.back-button {
    min-width: 10%;
    background: #FFFFFF;
    opacity: 0.5;
    border: 0.5px solid #87ABA9;
    border-radius: 5px;
    display: flex;
    align-self: center;
    justify-content: center;
}

.page-title {
    min-width: 80%;
    display: flex;
    align-self: center;
    justify-content: center;
}

.pagee-title-text {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #333333;
}

.clear-fix {
    min-width: 20%;
}

.profile-picture-container {
    display: block;
    align-self: center;
    justify-content: center;
    text-align: center;
    position: relative;
    flex-direction: column;
    padding: 10% 0;
}

.name {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #333333;
    padding: unset;
    margin: 5% 0;
}

.username {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #333333;
    padding: unset;
    margin: 5% 0;
}

.forms-container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.names-form-container {
    display: flex;
    flex-direction: row;
    position: relative;

}

.input-field-container-names {
    display: flex;
    flex-direction: row;
    min-width: 30%;
    background: rgba(63, 132, 132, 0.1);
    border-radius: 10px;
    color: #828282;
    align-items: center;
    height: 64px;
    border: 0.2px solid #005E60;
    margin-bottom: 10%;
}

.input-field-name {
    background: rgba(63, 132, 132, 0);
    color: #828282;
    border-color: rgba(63, 132, 132, 0);
    height: 100%;
    margin-left: 5%;
    width: 100%;
}

.input-field-dial-code {
    width: 15%;
    background: rgba(63, 132, 132, 0);
    color: #828282;
    border-color: rgba(63, 132, 132, 0);
    height: 100%;
    margin-left: 5%;
}

.input-field-phone-number {
    min-width: 70%;
    background: rgba(63, 132, 132, 0);
    color: #828282;
    border-color: rgba(63, 132, 132, 0);
    height: 100%;
}

.update-profile {
    background: #005E60;
    border-radius: 10px;
    height: 64px;
    color: #FFFFFF;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border: 2px solid #005E60;
}

.update-profile-picture {
    position: absolute;
    width: 24%;
    border-radius: 50%;
    background: #005E60;
    border: 0.725px solid #005E60;
    top: 45%;
    left: 67%;
}

.update-profile-picture>svg {
    fill: #005E60;
    color: #fff;
}

.errormessage {
    color: #FF0000;
    position: relative;
    padding-top: 3%;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
}

.w-13 {
    width: 13% !important;
}

.profile-picture {
    width: 50%;
    position: relative;
    border-radius: 50%;
}

.loadingbutton {
    position: relative;
    align-self: center;
    left: calc(50% - 108px/2 + 40.5px);
    --sk-color: #fff;
    --sk-size: 23px;
}