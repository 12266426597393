.container {
    position: relative;
    display: flex;
    flex-direction: row;
    background: #FAFAFA;
    justify-content: center;
    align-items: center;
    border: 0.1px solid rgba(0, 94, 96, 0.5);
    border-radius: 10px;
    padding: 6% 0%;
    margin-left: 25px;
    margin-right: 25px;
}


.mobileDetails-totalEarningsContainer {
    min-width: 100%;
    display: flex;
    flex-direction: row;
}

.leading {
    /* min-width: 24%; */
    /* padding-right: 12%; */
    margin-top: 5px;
    width: 30px;
    height: 30px;
    align-self: center;

}

.leadingIconContainer {
    border-radius: 100%;
    width: 24px;
    height: 24px;
    background: #CAD2D0;
    text-align: center;
}

.leadingIconContainer>svg,
.ending>svg {
    color: rgba(0, 94, 96, 0.5)
}

.descriptionContainer {
    min-width: 70%;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-self: center;
}

.heading {
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    color: #333333;
}

.subheading {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
}

.ending {
    min-width: 10%;
    align-content: center;
    align-self: center;
}

@media only screen and (min-width: 769px) {
    .container {
        display: none;

    

    }
}