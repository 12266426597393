.background {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: rgba(51, 51, 51, 0.7);
    backdrop-filter: blur(10px);
    z-index: 5;
    cursor: pointer;
}

.background-container {
    position: fixed;
    width: 450px;
    height: 450px;
    left: calc(50% - 450px/2 + 0.5px);
    top: calc(50% - 450px/2 + 0.5px);
    background: #FFFFFF;
    border-radius: 20px;
    z-index: 6;
}

.close-icon {
    position: absolute;
    left: 5.21%;
    right: 5.21%;
    top: 5.21%;
    bottom: 5.21%;
    width: 21.5px;
    height: 21.5px;
    cursor: pointer;
}

.close {
    position: absolute;
    width: 36px;
    height: 17px;
    left: 9.89%;
    top: 5.60%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 8px;
    cursor: pointer;
}

.successful {
    position: absolute;
    left: 35.22%;
    right: 6.33%;
    top: 20%;
    bottom: 0.38%;
    width: 123px;
    height: 123px;
    /* align-items: center; */
}

.trip-confirmation {
    position: absolute;
    width: 266px;
    height: 24px;
    left: calc(50% - 266px/2 + 0.5px);
    top: calc(50% - 24px/2 + 0.5px);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #181818;
}

.button-successful {
    position: absolute;
    left: 9%;
    right: 9%;
    top: 70%;
    bottom: 0%;
    background: #005E60;
    border: 1px solid #005E60;
    box-sizing: border-box;
    border-radius: 10px;
    width: 371px;
    height: 64px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}

@media only screen and (min-width:200px)and (max-width: 539px) {
    .background {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        background: rgba(51, 51, 51, 0.7);
        backdrop-filter: blur(10px);
        z-index: 5;
        cursor: pointer;
    }

    .background-container {
        position: fixed;
        width: 220px;
        height: 450px;
        /* width: 450px; */
        height: 450px;
        left: calc(50% - 220px/2 + 0.5px);
        top: calc(50% - 450px/2 + 0.5px);
        background: #FFFFFF;
        border-radius: 20px;
        z-index: 7;
    }

    .close-icon {
        position: absolute;
        left: 5.21%;
        right: 5.21%;
        top: 5.21%;
        bottom: 5.21%;
        width: 21.5px;
        height: 21.5px;
        cursor: pointer;
    }

    .close {
        position: absolute;
        width: 36px;
        height: 17px;
        left: 13.89%;
        top: 5.60%;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0px 8px;
        cursor: pointer;
    }

    .successful {
        position: absolute;
        left: 23.22%;
        right: 6.33%;
        top: 20%;
        bottom: 0.38%;
        width: 123px;
        height: 123px;
        /* align-items: center; */
    }

    .trip-confirmation {
        position: absolute;
        width: 150px;
        height: 24px;
        left: calc(50% - 150px/2 + 0.5px);
        top: calc(50% - 24px/2 + 0.5px);
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #181818;
    }

    .button-successful {
        position: absolute;
        left: 9%;
        right: 9%;
        top: 75%;
        bottom: 0%;
        background: #005E60;
        border: 1px solid #005E60;
        box-sizing: border-box;
        border-radius: 10px;
        width: auto;
        height: 64px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #FFFFFF;
        cursor: pointer;
    }
}

@media only screen and (min-width:540px)and (max-width: 820px) {
    .background {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        background: rgba(51, 51, 51, 0.7);
        backdrop-filter: blur(10px);
        z-index: 5;
        cursor: pointer;
    }

    .background-container {
        position: fixed;
        width: 450px;
        height: 450px;
        /* width: 450px; */
        height: 450px;
        left: calc(50% - 450px/2 + 0.5px);
        top: calc(50% - 450px/2 + 0.5px);
        background: #FFFFFF;
        border-radius: 20px;
        z-index: 7;
    }

    .close-icon {
        position: absolute;
        left: 5.21%;
        right: 5.21%;
        top: 5.21%;
        bottom: 5.21%;
        width: 21.5px;
        height: 21.5px;
        cursor: pointer;
    }

    .close {
        position: absolute;
        width: 36px;
        height: 17px;
        left: 9.89%;
        top: 5.60%;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0px 8px;
        cursor: pointer;
    }

    .successful {
        position: absolute;
        left: 34.22%;
        right: 6.33%;
        top: 20%;
        bottom: 0.38%;
        width: 123px;
        height: 123px;
        /* align-items: center; */
    }

    .trip-confirmation {
        position: absolute;
        width: auto;
        height: 24px;
        /* left: calc(50% - auto/2 + 0.5px); */
        top: calc(50% - 24px/2 + 0.5px);
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #181818;
    }

    .button-successful {
        position: absolute;
        left: 9%;
        right: 9%;
        top: 75%;
        bottom: 0%;
        background: #005E60;
        border: 1px solid #005E60;
        box-sizing: border-box;
        border-radius: 10px;
        width: auto;
        height: 64px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #FFFFFF;
        cursor: pointer;
    }
}