@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('~spinkit/spinkit.css');
@import url('~animate.css/animate.css');
@import url('./custom.css');

* {
  box-sizing: border-box;
}

html {
  font-family: 'Rubik', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background-color: #fff;
}

.title p {
  position: fixed;
  width: 58px;
  line-height: 28px;
  left: 120px;
  top: 30px;
  color: #000000;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  margin: 0px 21px;
}