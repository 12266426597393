.container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 5% 5% 1% 5%;
    height: 100vh;
    background: #FAFAFA;
}

.title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #333333;
    margin: 0 0 3% 0;
}

.item-container {
    border-top: 0.5px solid rgba(63, 132, 132, 0.2);
    border-bottom: 0.5px solid rgba(63, 132, 132, 0.2);
    position: relative;
    display: flex;
    flex-direction: row;
    padding-top: 1%;
    padding-bottom: 1%;
}

.title-left {
    min-width: 10%;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
}

.title-end {
    min-width: 90%;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
}

.title-left-message {
    min-width: 20%;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
}

.title-end-message {
    min-width: 80%;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
}