.container {
    position: relative;
    display: flex;
    flex-direction: row;
    opacity: 0.5;
    border: 0.5px solid #87ABA9;
    padding: 2%;
}

.descriptions {
    position: relative;
    display: flex;
    min-width: 50%;
    flex-direction: column;
}

.heading {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
}

.sub-title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #4F4F4F;
    margin: 0;
}

.action {
    position: relative;
    display: flex;
    min-width: 50%;
    flex-direction: column;
    align-self: center;
    align-items: end;
}

.action-button {
    width: 93px;
    height: 36px;
    left: 982px;
    top: 248px;
    background: #00CE60;
    border-radius: 5px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    border: 2px solid #00CE60;
}