.container {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 90vw;
    height: 100vh;
    margin-left: 10%;
    padding: 1% 0 0 2%;
    background: #FAFAFA;
}

.header-container {
    min-width: 100%;
    position: relative;
}

.sub-header-container {
    min-width: 10%;
    width: 40%;
    position: relative;
}

.title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #333333;
}

.sub-title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #4F4F4F;
}

@media only screen and (max-width: 769px) {
    .container {
        width: unset;
        margin-left: unset;
        padding-left: 6%;
    }

    .sub-header-container {
        width: unset;
    }
}