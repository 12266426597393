.container {
  box-sizing: border-box;
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 50px 50px;
  width: 702px;
  left: calc(50% - 702px / 2 - 0.5px);
  top: calc(50% - 500px / 2 - 0.5px);
  background: #ffffff;
  border: 0.2px solid #87aba9;
  border-radius: 10px;
}

.heading {
  position: relative;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  margin-top: 2px;
  margin-bottom: 10px;
  line-height: 38px;
  text-align: center;
  color: #181818;
}

.subheading {
  position: relative;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  margin-top: 2px;
  margin-bottom: 10px;
  text-align: center;
  color: #181818;
}

.phonenumberfield {
  align-items: flex-start;
  padding: 23px 22px;
  gap: 10px;
  margin-top: 5%;
  position: relative;
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  height: 68px;
  background: rgba(0, 94, 96, 0.04);
  border-radius: 10px;
  border-color: rgba(0, 94, 96, 0.04);
  border: 0.2px solid rgba(0, 94, 96, 0.04);
  color: #828282;
}

.phonenumberfield:focus {
  outline: 1.3px solid #87aba9;
}

::placeholder {
  color: #828282;
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  line-height: 19px;
}

.continue {
  align-items: center;
  margin-top: 5%;
  padding: 20px;
  gap: 20px;
  position: relative;
  width: 100%;
  height: 68px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  background: #005e60;
  border: 1px solid #005e60;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
}

.continue:hover {
  background: #3f8484;
  border: 1px solid #3f8484;
}

.errormessage {
  color: #ff0000;
  position: relative;
  padding-top: 3%;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.loadingbutton {
  position: relative;
  align-self: center;
  left: calc(50% - 108px / 2 + 40.5px);
  --sk-color: #fff;
  --sk-size: 23px;
}

.mobilePixContainer {
  min-width: 100%;
  justify-content: center;
  align-items: center;
}

.mobilePix {
  display: none;
  width: 100%;
}

.login {
  position: relative;
  padding-top: 2%;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #333333;
}

.login>* {
  color: #005E60;
  font-weight: 700;
  text-decoration: none;
}

.login a:hover {
  color:#3F8484;
}

@media only screen and (max-width: 916px) {
  .mobilePix {
    display: block;
    padding-bottom: 3%;
  }

  .container {
    border: unset;
    top: calc(50% - 883px / 2 - 0.5px);
    left: unset;
    top: unset;
    width: 100%;
    margin: 0;
    padding-left: 25px;
    padding-right: 25px;
  }

  .heading {
    position: relative;
    font-style: normal;
    font-weight: 700;
    width: 100%;
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 5px;
    line-height: 36px;
    text-align: center;
    color: #181818;
  }

  .phonenumberfield {
    align-items: center;
    font-size: 12px;
  }

  ::placeholder {
    font-size: 12px;
  }

  .continue {
    font-size: 12px;
  }
  .login {
    font-size: 12px;
}
}
