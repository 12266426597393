.background {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: rgba(51, 51, 51, 0.7);
    backdrop-filter: blur(10px);
    z-index: 5;
    cursor: pointer;
} 

.background-container {
    position: absolute;
    width: 450px;
    height: 450px;
    left: calc(50% - 450px/2 + 0.5px);
    top: calc(50% - 450px/2 + 0.5px);
    background: #FFFFFF;
    border-radius: 20px;
    z-index: 6;
}

.close-icon {
    position: absolute;
    left: 5.21%;
    right: 5.21%;
    top: 5.21%;
    bottom: 5.21%;
    width: 21.5px;
    height: 21.5px;
    cursor: pointer;
}

.close {
    position: absolute;
    width: 36px;
    height: 17px;
    left: 9.89%;
    top: 5.60%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 8px;
    cursor: pointer;
}


.trip-confirmation {
    position: absolute;
    width: 266px;
    height: 24px;
    left: calc(50% - 266px/2 + 0.5px);
    top: calc(50% - 60px/2 + 0.5px);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #4F4F4F;
}

@media only screen and (min-width:200px)and (max-width: 539px) {
    .background-container {
        position: absolute;
        width: 220px;
        height: 450px;
        left: calc(50% - 220px/2 + 0.5px);
        top: calc(50% - 450px/2 + 0.5px);
        background: #FFFFFF;
        border-radius: 20px;
        z-index: 6;
    }   

    .trip-confirmation {
        position: absolute;
        width: 150px;
        height: 24px;
        left: calc(50% - 150px/2 + 0.5px);
        top: calc(50% - 60px/2 + 0.5px);
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #4F4F4F;
    }
}