/* @import url('bootstrap/dist/css/bootstrap.min.css'); */

.preloader {
  position: relative;
  top: 200px;
  left: 450px;
  height: 100vh;
  --sk-color: #005e60;
  --sk-size: 70px;
}

.heading-container {
  position: fixed;
  width: 100vw;
  height: 12vh;
  top: 0;
  z-index: 1;
  background-color: #ffffff;
}

.title p {
  position: relative;
  width: 56px;
  line-height: 0px;
  left: 4px;
  top: 50px;
  bottom: 0;
  color: #000000;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  margin: 0 21px;
}

.flex-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  left: 120px;
  height: 100%;
  width: calc(50% - 120px);
  top: 74px;
}

.sub-title {
  position: absolute;
  height: 17px;
  left: 25px;
  top: 66px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.sub-title-mobile {
  position: absolute;
  height: 17px;
  left: 21px;
  display: none;
  top: 66px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  text-align: start;
}

.mt-2 {
  margin-top: 2%;
}

.main-view-container {
  margin-top: 10%;
  position: absolute;
  box-sizing: border-box;
  display: flex;
  padding: 0 5% 0 5%;
  width: 88vw;
  flex-direction: column;
}

.chart-container {
  position: relative;
  width: 80vw;
  height: 50px;
  background: #fafafa;
  border-radius: 9.83881px;
}

.chart-container-height {
  height: 50vh;
}

.edge-marker {
  position: absolute;
  width: 26px;
  height: 0px;
  left: 23px;
  top: 45px;
  background: #3f8484;
  border: 1px solid #3f8484;
  transform: rotate(90deg);
}

.chart-title {
  position: absolute;
  height: 17px;
  left: 58px;
  top: 0px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 17px;
  color: #4f4f4f;
  width: 263px;
}

.chart-subtitle {
  position: absolute;
  height: 12px;
  left: 57px;
  top: 40px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #4f4f4f;
  width: 105px;
}

.chart-select {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10.4712px;
  margin-top: 1.5%;
  gap: 30px;
  position: absolute;
  font-style: normal;
  font-weight: 400;
  font-size: 10.0537px;
  line-height: 140.3%;
  width: 108px;
  height: 36.94px;
  left: calc(50% - 108px / 2 + 419.5px);
  /* top: calc(50% - 34.94px / 2 - 213.03px); */
  background: #ffffff;
  border: 0.951923px solid #005e60;
  border-radius: 4.75962px;
  color: #005e60;
  cursor: pointer;
}

.chart-select:focus {
  outline: 1.3px solid #005e60;
}

.bar-chart {
  width: 80%;
  height: 340px;
  position: relative;
  top: 90px;
  left: 25px;
}

.table-container {
  box-sizing: border-box;
  margin-top: 4%;
  position: relative;
  width: 87vw;
  height: 60vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background: #fafafa;
  border-radius: 9.83881px;
}

.table-title {
  position: relative;
  height: 19px;
  margin-top: 3.5%;
  margin-left: 2.5%;
  font-style: normal;
  font-weight: 500;
  min-width: 50%;
  font-size: 16px;
  align-self: center;
  line-height: 19px;
  color: #005e60;
}

.table-filter {
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding: 10.4712px;
  margin-top: 5%;
  gap: 30px;
  position: relative;
  width: 210px;
  left: 10%;
  height: 36.94px;
  /* left: calc(50% - 108px / 2 + 354.5px);
    top: calc(50% - 34.94px / 2 - 441.03px); */
  background: #ffffff;
  border: 1px solid #005e60;
  border-radius: 4.75962px;
  color: #005e60;
}

.table-filter:focus {
  outline: 1.3px solid #005e60;
}

.table-filter::placeholder {
  font-style: normal;
  font-weight: 550;
  font-size: 10px;
  line-height: 12px;
}

.table-controls {
  position: relative;
  min-width: 100%;
  display: flex;
  flex-direction: row;
}

.align-controls {
  display: flex;
  min-width: 50%;
  flex-direction: row;
  justify-content: space-around;
}

.table-button {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 11px;
  gap: 10px;
  margin-top: 5%;
  position: relative;
  width: 120px;
  /* left: 1%; */
  height: 36px;
  right: 0.5%;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* left: calc(50% - 108px / 2 + 634.5px);
    top: 10px; */
  background: #005e60;
  border: 0.951923px solid #005e60;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}

.downloading-button {
  position: absolute;
  align-self: center;
  --sk-color: #fff;
  --sk-size: 23px;
}

.table-flex {
  margin-top: 5%;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 65vh;
  overflow: auto;
}

.table {
  position: relative;
  width: 95%;
  left: 26px;
  padding-bottom: 25px;
}

.table > thead > tr > th,
.table > tbody > tr > td {
  /*position: absolute;*/
  width: 12%;
  text-align: start;
}

.table > thead > tr > th {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #4f4f4f;
  padding: 13px 3px;
}

.table > tbody > tr > td {
  position: relative;
  align-items: center;
  background-color: #fff;
  padding: 13px 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #4f4f4f;
}

.tags {
  width: 2% !important;
}

.no-result {
  position: relative;
  text-align: center !important;
  top: 10px;
  left: 20px;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 17px !important;
  line-height: 17px;
  color: #4f4f4f;
}

.tag-danger {
  /*position: absolute;*/
  width: 26px;
  height: 0;
  /*left: 180px;*/
  /*top: 197px;*/
  background: #ff8282;
  border: 1px solid #ff8282;
  transform: rotate(90deg);
}

.tag-success {
  /*position: absolute;*/
  width: 26px;
  height: 0;
  /*left: 180px;*/
  /*top: 197px;*/
  background: #2ba755;
  border: 1px solid #2ba755;
  transform: rotate(90deg);
}

.tag-info {
  /*position: absolute;*/
  width: 26px;
  height: 0;
  /*left: 180px;*/
  /*top: 197px;*/
  background: #005e60;
  border: 1px solid #005e60;
  transform: rotate(90deg);
}

.tag-warning {
  /*position: absolute;*/
  width: 26px;
  height: 0;
  /*left: 180px;*/
  /*top: 197px;*/
  background: #fbbd00;
  border: 1px solid #fbbd00;
  transform: rotate(90deg);
}

.txt-danger {
  color: #ee3737 !important;
}

.txt-success {
  color: #00ce60 !important;
}

.auto-capitalize {
  text-transform: capitalize;
}

.d-none {
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.printout-table-container {
  position: relative;
  width: 300px;
  /* left: 146px; */
  display: flex;
  flex-direction: column;
  padding: 5% 8% 15% 5%;
  top: 95px;
  background: #fffffe;
  box-shadow: -1px 6px 18px rgba(0, 0, 0, 0.1);
}

.printout-heading {
  position: absolute;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.printout-info {
  display: flex;
  margin-top: 6%;
  flex-direction: row;
  border-top: 1px solid #828282;
  border-bottom: 1px solid #828282;
}

.printout-username {
  font-family: "Rubik";
  flex: 4;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.printout-invoicetitle {
  font-family: "Rubik";
  font-style: normal;
  flex: 1;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.printout-invoicedate {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.printout-table {
  position: relative;
  width: 100%;
  margin-top: 2%;
  padding-bottom: 25px;
  border-collapse: collapse;
}

.printout-table-first {
  width: 24% !important;
  text-align: start !important;
}

.printout-table-last {
  text-align: end !important;
  width: 24% !important;
}

.printout-table > thead:after {
  content: "@";
  display: block;
  line-height: 17px;
  text-indent: -99999px;
}

.printout-table-body {
  border-top: 0.5px solid #828282;
  border-bottom: 0.5px solid #828282;
  margin-top: 5% !important;
}

.printout-table > thead > tr > th,
.printout-table > tbody > tr > td {
  width: 19%;
  text-align: center;
}

.printout-table > thead > tr > th {
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 14px;
  color: #4f4f4f;
  padding: 13px 0;
}

.printout-table > tbody > tr > td {
  align-items: center;
  background-color: #fff;
  padding: 13px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #4f4f4f;
}

.subtotal-table {
  margin-top: 4%;
  width: 40%;
  align-self: end;
  display: flex;
  flex-direction: column;
}

.mb-2 {
  margin-bottom: 2%;
}

.sub-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 4%;
}

.total-deduction {
  flex: 4;
  text-align: left;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.total-decution-amount {
  flex: 4;
  text-align: right;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
}

.border-bottom {
  border-bottom: 0.5px solid #828282;
}

.pb-3 {
  padding-bottom: 3%;
}

.pagination-container {
  display: flex;
  margin-top: 3%;
  position: relative;
  flex-direction: row;
}

.pagination-information {
  position: relative;
  min-width: 50%;
  align-self: center;
}

.pagination-information-text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
}

.pagination-control {
  position: relative;
  min-width: 50%;
  text-align: end;
}
.pagination-control-container {
  position: relative;
  color: #005e60;
  display: flex;
  justify-content: flex-end;
  align-self: center;
  align-items: center;
  background-color: #fafafa;
  width: unset;
}

.pagination-control-container > svg {
  fill: #005e60;
  top: 10%;
}

.active-pagination {
  border-radius: 10px;
  background-color: #005e60;
  padding: 2% 3%;
  color: #fff !important;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  margin: 0 3%;
  cursor: default;
}
.inactive-pagination {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  margin: 0 3%;
  top: 100%;
  cursor: pointer;
}

@media only screen and (max-width: 769px) {
  .mt-5 {
    margin-top: 5%;
  }

  .container {
    display: block;
    border: unset;
    top: calc(50% - 883px / 2 - 0.5px);
    left: unset;
    top: unset;
    width: unset;
    margin: 0;
    padding: 0;
  }

  .table-controls {
    flex-direction: column;
  }

  .table {
    left: unset;
  }

  .align-controls {
    margin-top: 5%;
  }

  .flex-container {
    left: unset;
  }

  .chart-select {
    left: calc(50% - 90px / 2 + 50% - 43.5px);
    top: calc(50% - 32px / 2 - 54.03px);
    margin: 0;
    padding: 0;
    margin-top: 2%;
    background: #fafafa;
    border-radius: 3px;
    border: none;
    height: 32px;
    width: 90px;
    padding-left: 12px;
    padding-right: 12px;
    position: relative;
  }

  .chart-select:focus {
    outline: 1.3px solid #fafafa;
  }

  .edge-marker,
  .chart-title,
  .chart-subtitle,
  .table-container {
    display: none;
  }

  .chart-container {
    position: relative;
    background: #fff;
    width: unset;
    padding: none;
    margin: none;
    margin-left: 19px;
    margin-right: 25px;
    /* margin-bottom: 15px; */
  }

  .title p {
    display: unset;
    top: 60px;
  }

  .sub-title-mobile {
    display: unset;
    top: 90px;
    margin-left: 5px;
  }

  .bar-chart {
    display: block;
    top: 100px;
    width: 100%;
    position: relative;
    left: 0;
  }

  /* .bar-chart {
        width: 80%;
        height: 340px;
        position: absolute;
        top: 90px;
        left: 25px;
    } */

  .sub-title {
    display: none;
  }

  .heading-container {
    background-color: #005e60;
    height: 160px;
    /* height: 23vh; */
  }

  .heading-container > *,
  .title p {
    color: #fff;
  }

  .sub-title > b {
    width: 100%;
    word-break: break-word;
  }

  .chart-container-height {
    height: 330px;
  }

  .main-view-container {
    width: 100vw;
    height: 90vh;
  }

  .table-container {
    width: 100vw;
  }
}
