.background {
    position: absolute;
    width: 620px;
    height: 100%;
    top: 0px;
    z-index: 20;
    display: flex;
    min-width: 100vw;
    background: transparent;
    justify-content: end;
}

.left-side {
    position: relative;
    display: none;
    min-width: 50vw;
}

.right-side {
    position: relative;
    display: flex;
    min-width: 50vw;
    background: #FAFAFA;
}

.trip-header {
    position: relative;
    width: 87px;
    height: 19px;
    left: 21px;
    top: 52px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
}

.trip-header-line {
    position: absolute;
    width: 538px;
    height: 0px;
    left: 21px;
    top: 108px;
    border: 0.2px solid #CAD2D0;
}

.tripID {
    position: absolute;
    width: 45px;
    height: 14px;
    left: 21px;
    top: 116px;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #333333;
}

.tripID-value {
    position: absolute;
    width: auto;
    height: 19px;
    left: 21px;
    top: 145px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
}

.vehicleNo {
    position: absolute;
    width: 58px;
    height: 14px;
    right: 61px;
    top: 116px;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #333333;
}

.vehicleNo-value {
    position: absolute;
    width: auto;
    height: 19px;
    right: 61px;
    top: 145px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
}

.trip-line1 {
    position: absolute;
    width: 538px;
    height: 0px;
    left: 21px;
    top: 190px;
    border: 0.2px solid #CAD2D0;
}

.items {
    position: absolute;
    width: 58px;
    height: 14px;
    left: 21px;
    top: 195px;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #333333;
}

.items-value {
    position: absolute;
    width: auto;
    height: 19px;
    left: 21px;
    top: 224px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
}

.distance {
    position: absolute;
    width: 48px;
    height: 14px;
    right: 61px;
    top: 195px;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #333333;
}

.distance-value {
    position: absolute;
    width: 49px;
    height: 19px;
    right: 61px;
    top: 224px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
}

.trip-line2 {
    position: absolute;
    width: 538px;
    height: 0px;
    left: 21px;
    top: 269px;
    border: 0.2px solid #CAD2D0;
}

.start-location {
    position: absolute;
    width: 77px;
    height: 14px;
    left: 21px;
    top: 274px;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #333333;
}

.start-location-value {
    position: absolute;
    width: auto;
    height: 19px;
    left: 21px;
    top: 303px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    text-transform: capitalize;
}

.end-location {
    position: absolute;
    width: 71px;
    height: 14px;
    right: 61px;
    top: 274px;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #333333;
}

.end-location-value {
    position: absolute;
    width: auto;
    height: 19px;
    right: 61px;
    top: 303px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    text-transform: capitalize;
}

.trip-line3 {
    position: absolute;
    width: 538px;
    height: 0px;
    left: 21px;
    top: 348px;
    border: 0.2px solid #CAD2D0;
}

.trip-status {
    position: absolute;
    width: 58px;
    height: 14px;
    left: 21px;
    top: 353px;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #333333;
}

.trip-status-value {
    position: absolute;
    width: auto;
    height: 19px;
    left: 21px;
    top: 382px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #CD9B02;
    text-transform: capitalize;
}

.payment-status {
    position: absolute;
    width: 87px;
    height: 14px;
    right: 61px;
    top: 353px;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #333333;
}

.payment-status-value {
    position: absolute;
    width: auto;
    height: 19px;
    right: 61px;
    top: 382px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    text-transform: capitalize;
}

.trip-line4 {
    position: absolute;
    width: 538px;
    height: 0px;
    left: 21px;
    top: 427px;
    border: 0.2px solid #CAD2D0;
}

.journey-button {
    position: absolute;
    height: 56px;
    left: 21px;
    right: 61px;
    top: 460px;
    bottom: 18px;
    background: #005E60;
    border: 0.187317px solid #005E60;
    border-radius: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}

.journey-button:hover {
    background:#3F8484;
    border: 1px solid #3F8484;
}

.fuel-button {
    position: absolute;
    background: #FAFAFA;
    height: 56px;
    left: 21px;
    right: 61px;
    top: 530px;
    bottom: 30px;
    border: 0.187317px solid #005E60;
    border-radius: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #005E60;
    cursor: pointer;
}

.fuel-button:hover {
    background: #005E60;
    border: 1px solid #005E60;
    color: #FFFFFF;
}

.firstName {
    text-transform: capitalize;
}

@media only screen and (min-width:200px)and (max-width: 820px) {
    .background {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0px;
        background: #FFFFFF;
    }
    
    .trip-header {
        position: relative;
        width: 87px;
        height: 19px;
        left: 25px;
        top: 52px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #333333;
    }
    
    .trip-header-line {
        position: absolute;
        width: auto;
        height: 0px;
        left: 25px;
        right: 25px;
        top: 108px;
        border: 0.2px solid #CAD2D0;
    }
    
    .tripID {
        position: absolute;
        width: 45px;
        height: 14px;
        left: 25px;
        top: 116px;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        color: #333333;
    }
    
    .tripID-value {
        position: absolute;
        width: auto;
        height: 19px;
        left: 25px;
        top: 145px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #333333;
    }
    
    .vehicleNo {
        position: absolute;
        width: 58px;
        height: 14px;
        right: 25px;
        top: 116px;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        color: #333333;
    }
    
    .vehicleNo-value {
        position: absolute;
        width: auto;
        height: 19px;
        right: 25px;
        top: 145px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #333333;
    }
    
    .trip-line1 {
        position: absolute;
        width: auto; 
        height: 0px;
        left: 25px;
        right: 25px;
        top: 190px;
        border: 0.2px solid #CAD2D0;
    }
    
    .items {
        position: absolute;
        width: 58px;
        height: 14px;
        left: 25px;
        top: 195px;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        color: #333333;
    }
    
    .items-value {
        position: absolute;
        width: auto;
        height: 19px;
        left: 25px;
        top: 224px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #333333;
    }
    
    .distance {
        position: absolute;
        width: 48px;
        height: 14px;
        right: 25px;
        top: 195px;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        color: #333333;
    }
    
    .distance-value {
        position: absolute;
        width: 49px;
        height: 19px;
        right: 25px;
        top: 224px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #333333;
    }
    
    .trip-line2 {
        position: absolute;
        width: auto;
        height: 0px;
        left: 25px;
        right: 25px;
        top: 269px;
        border: 0.2px solid #CAD2D0;
    }
    
    .start-location {
        position: absolute;
        width: 77px;
        height: 14px;
        left: 25px;
        top: 274px;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        color: #333333;
    }
    
    .start-location-value {
        position: absolute;
        width: auto;
        height: 50px;
        left: 25px;
        top: 303px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #333333;
        text-transform: capitalize;
    }
    
    .end-location {
        position: absolute;
        width: 71px;
        height: 14px;
        right: 25px;
        top: 274px;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        color: #333333;
    }
    
    .end-location-value {
        position: absolute;
        width: auto;
        height: 50px;
        right: 25px;
        top: 303px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #333333;
        text-transform: capitalize;
    }
    
    .trip-line3 {
        position: absolute;
        width: auto;
        height: 0px;
        left: 25px;
        right: 25px;
        top: 348px;
        border: 0.2px solid #CAD2D0;
    }
    
    .trip-status {
        position: absolute;
        width: 58px;
        height: 14px;
        left: 25px;
        top: 353px;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        color: #333333;
    }
    
    .trip-status-value {
        position: absolute;
        width: auto;
        height: 19px;
        left: 25px;
        top: 382px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #CD9B02;
        text-transform: capitalize;
    }
    
    .payment-status {
        position: absolute;
        width: 87px;
        height: 14px;
        right: 25px;
        top: 353px;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        color: #333333;
    }
    
    .payment-status-value {
        position: absolute;
        width: auto;
        height: 19px;
        right: 25px;
        top: 382px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #333333;
        text-transform: capitalize;
    }
    
    .trip-line4 {
        position: absolute;
        width: auto;
        height: 0px;
        left: 25px;
        right: 25px;
        top: 427px;
        border: 0.2px solid #CAD2D0;
    }
    
    .journey-button {
        position: absolute;
        width: auto;
        height: 56px;
        left: 25px;
        right: 25px;
        top: 460px;
        bottom: 18px;
        background: #005E60;
        border: 0.187317px solid #005E60;
        border-radius: 10px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #FFFFFF;
        cursor: pointer;
    }
    
    .journey-button:hover {
        background:#3F8484;
        border: 1px solid #3F8484;
    }
    
    .fuel-button {
        position: absolute;
        background: #FAFAFA;
        width: auto;
        height: 56px;
        left: 25px;
        right: 25px;
        top: 530px;
        bottom: 30px;
        border: 0.187317px solid #005E60;
        border-radius: 10px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #005E60;
        cursor: pointer;
    }
    
    .fuel-button:hover {
        background: #005E60;
        border: 1px solid #005E60;
        color: #FFFFFF;
    }
    
    .firstName {
        text-transform: capitalize;
    }
}

