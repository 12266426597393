.container {
    flex-direction: column;
    width: 100vw;
    padding: 12% 7% 0 7%;
    display: flex;
    position: absolute;
}

.title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #181818;
}

.user-information {
    background: rgba(135, 171, 169, 0.3);
    border-radius: 10px;
    padding: 5%;
    display: flex;
    position: relative;
    margin-top: 10%;
    flex-direction: row;
}

.image-container {
    position: relative;
    min-width: 10%;
}

.user-description {
    position: relative;
    min-width: 75%;
    display: flex;
    flex-direction: column;
    padding-left: 4%;
    align-self: center;
}

.user-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 150%;
    color: #212121;
    padding: unset;
    margin: unset;
}

.name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #212121;
    padding: unset;
    margin: unset;
}

.trail {
    position: relative;
    display: flex;
    min-width: 5%;
    flex-direction: column;
    align-self: center;
    align-items: end;
}

.menu {
    flex-direction: column;
    display: flex;
    position: relative;
    padding-bottom: 35%;
}

.item {
    flex-direction: row;
    margin-top: 20%;
    display: flex;
    position: relative;
    border-bottom: 0.3px solid #87ABA9;
}

.item-head {
    position: relative;
    min-width: 10%;
}

.item-description {
    position: relative;
    min-width: 80%;
    padding-left: 5%;
}

.item-description>p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #212121;
    padding: unset;
    margin: unset;
}

.sign-out {
    color: #FF8282 !important;
}

.item-trail {
    position: relative;
    min-width: 10%;
}

a {
    color: inherit;
    text-decoration: none;
}

.profile-picture {
    width: 68%;
    position: relative;
    border-radius: 50%;
}