.menu-layout {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    /* padding: 33px 33px; */
    left: 0;
    position: fixed;
    width: 110px;
    height: 100vh;
    background: #FFFFFE;
    z-index: 1;
}

.icon-nav a {
    display: block;
    padding: 0 15px;
    padding-top: 40px;
    line-height: 14px;
    color: #828282;
    font-size: 12px;
    text-decoration: none;
    text-align: center;
    font-weight: 300;
    font-size: 10px;
}

.icon-nav li {
    list-style: none;
    /* padding: 2%; */
}

.icon-nav a:hover,
.icon-nav a:active {
    color: #005E60;
}

.focused>svg {
    fill: #005E60;
    color: #fff;

}

.focused-text {
    color: #005E60;
    font-weight: 500;

}

/* .icon-nav {
    display: block;
    width: 30px;
    height: 100px;
    position: fixed;
    width: 109px;
    height: 100%;
    left: 1px;
    top: 40px;
} */

a.logout {
    color: #FF8282;
}

a.logout:hover,
a.logout:active {
    color: #f8baba;
}

@media only screen and (min-width: 200px) and (max-width: 280px) {
    .menu-layout { 
        position: fixed;
        min-width: 539px;
        height: 80px;
        bottom: 0;
        left: 0;
        width: auto;
        top: auto;
        background: #FFFFFE;
    } 
    
    .icon-nav ul {
        position: fixed;
        display: flex;
        bottom: 8px;
        margin: 0;
        padding: 0;
        width: auto;
        left: 5px;
        right: 5px;
    }
    
    .icon-nav ul li a {
        padding: 3px 13px;
        color: #828282;
        font-size: 5px;
        text-decoration: none;
        font-weight: 300;
        font-size: 10px;
        
    }

    .iconNon {
        display: none;
    }
    
    .icon-nav li {
        list-style: none;
        padding: 0%;
    }
    
    .icon-nav a:hover,
    .icon-nav a:active {
        color: #005E60;
    }
    
    .focused > svg {
        fill: #005E60; 
        color: #fff;
        
    }
    
    .focused-text {
        color: #005E60;
    }
    
    .icon-nav ul li a.logout {
        color: #FF8282;
    }

    .icon-nav ul li a.logout:hover,
    .icon-nav ul li a.logout:active {
        color: #f8baba;
    }


    
}

@media only screen and (min-width: 281px) and (max-width: 539px) {
    .menu-layout { 
        position: fixed;
        min-width: 539px;
        height: 80px;
        bottom: 0;
        width: 100%;
        top: auto;
        background: #FFFFFE;

    } 
    
    .icon-nav ul {
        position: fixed;
        display: flex;
        bottom: 8px;
        margin: 0;
        padding: 0;
        width: auto;
        left: 3px;
        right: 3px;
    }
    
    .icon-nav ul li a {
        padding: 3px 25px;
        color: #828282;
        font-size: 5px;
        text-decoration: none;
        font-weight: 300;
        font-size: 10px;
        
    }

    .iconNon {
        display: none;
    }
    
    .icon-nav li {
        list-style: none;
        padding: 0%;
    }
    
    .icon-nav a:hover,
    .icon-nav a:active {
        color: #005E60;
    }
    
    .focused > svg {
        fill: #005E60; 
        color: #fff;
        
    }
    
    .focused-text {
        color: #005E60;
    }
    
    .icon-nav ul li a.logout {
        color: #FF8282;
    }

    .icon-nav ul li a.logout:hover,
    .icon-nav ul li a.logout:active {
        color: #f8baba;
    }

    
}

@media only screen and (min-width: 540px) and (max-width: 820px) {
    .menu-layout { 
        position: fixed;
        min-width: 820px;
        height: 99px;
        bottom: 0px;
        width: auto;
        top: auto;
        background: #FFFFFE;
        z-index: -1;
    } 
    
    .icon-nav ul {
        position: fixed;
        display: flex;
        flex-direction: row;
        bottom: 8px;
        margin: 0;
        padding: 0;
        width: auto;
        left: 1px;
        right: 1px;
    }
    
    .icon-nav ul li a {
        padding: 3px 80px;
        color: #828282;
        font-size: 5px;
        text-decoration: none;
        font-weight: 300;
        font-size: 10px;
        
    }
    
    .icon-nav li {
        list-style: none;
        padding: 0%;
    }
    
    .icon-nav a:hover,
    .icon-nav a:active {
        color: #005E60;
    }
    
    .focused > svg {
        fill: #005E60; 
        color: #fff;
        
    }
    
    .focused-text {
        color: #005E60;
    }

    .iconNon {
        display: none;
    }


    


    
}