
/* width */
::-webkit-scrollbar {
    width: 13px;
    height: 13px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 94, 96, 0.5); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 94, 96, 1);; 
  }
  
  /* ::-webkit-scrollbar-button {
    background-color: #fff;
    border-style: solid;
    height: 13px;
    width: 16px;
  } */
  /* Up */
  /* ::-webkit-scrollbar-button:single-button:vertical:decrement {
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #005e60 transparent;
  }
  
  ::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    border-color: transparent transparent #005e60 transparent;
  } */
  /* Down */
  /* ::-webkit-scrollbar-button:single-button:vertical:increment {
    border-width: 8px 8px 0 8px;
    border-color: #005e60 transparent transparent transparent;
  }
  
  ::-webkit-scrollbar-button:vertical:single-button:increment:hover {
    border-color: #005e60 transparent transparent transparent;
  } */