.container {
    box-sizing: border-box;
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 50px 50px 0 50px;
    width: 702px;
    left: calc(50% - 702px/2 + 0.5px);
    top: calc(50% - 450px/2);
    background: #FFFFFF;
    border: 0.2px solid #87ABA9;
    border-radius: 10px;
}

.confirmimage {
    position: relative;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    align-self: center;
    width: 50%;
    font-size: 32px;
    margin-bottom: 10px;
    line-height: 38px;
    text-align: center;
    color: #181818;
}

.greetingheading {
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    line-height: 28px;
    color: #181818;
}

.greetingheading {
    position: relative;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0;
    font-size: 24px;
    text-align: center;
    line-height: 28px;
    color: #181818;
}

.greetingsubtitle {
    position: relative;
    font-style: normal;
    text-align: center;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    color: #181818;
}

.mobilePixContainer {
    min-width: 100%;
    justify-content: center;
    align-items: center;
}

.mobilePix {
    display: none;
    width: 100%;
}

@media only screen and (max-width: 769px) {
    .mobilePix {
        display: block;
    }

    .hideBackground {
        display: none;
    }

    .confirmimage {
        display: none;
    }

    .container {
        border: unset;
        top: calc(50% - 883px/2 - 0.5px);
        left: unset;
        top: unset;
        width: unset;
    }
}