.container {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 2%;
    background: #FAFAFA;
    margin-bottom: 2%;
}

.picture-part {
    min-width: 15%;
    align-self: center;
}

.message-part {
    min-width: 70%;
}

.message-part-container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.message-part-container>h4 {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #333333;
    padding: unset !important;
    margin: unset !important;
}

.message-part-container>p {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 12px;
    color: #4F4F4F;
}

.details-part {
    min-width: 15%;
    justify-content: flex-end;
}

.details-part-container {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: flex-end;
    justify-items: end;
    align-items: flex-end;
    align-self: center;
}

.details-part-counter {
    background: #3F8484;
    border-radius: 50%;
    text-align: center;
    color: #FFFFFF;
    width: 15%;
}

.details-part-container>p {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    text-align: right;
    color: #005E60;
}

