.background {
    /* Remember to change this position to relative */
    position: relative;
    /* width: calc(50% + 265px - 44px); */
    top: 0px;
    bottom: 90px;
    /* left: 120px; */
    /* margin: 0px 21px; */
    margin-bottom: 22px;
    Height: 280px;
    background: #FAFAFA;
    border-radius: 10px;
    padding-right: 6%;
    /* scroll-behavior: smooth; */
}
/* .container {
    min-height: 370px;
    min-width: calc(50% - 120px - 44px);
    left: 120px;
    position: fixed;
    margin: 222px 141px;
    background: blue;
    border-radius: 10px;
    overflow-y: auto;
    } */

.circle-4 {
    position: relative;
    color: #005E60;
    min-width: 25%;
    height: 15px;
}

.trip-info-contain {
    position: relative;
    top: 24.33%;
    margin-left: 8%;
    bottom: 8.33%;
    display: flex;
    flex-direction: row;
}

.trip-id {
    position: relative;
    min-width: 25%;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    color: #4F4F4F;
    width: 119px;
}

.trip-id > h6 {
    margin: 0%;
}

.button-assigned {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    position: fixed;
    width: 120px;
    height: 43px;
    left: 135px;
    /* margin: 0px 21px; */
    top: 145px;
    /* background: #005E60; */
    /* border-radius: 10px; */
}

.button-assigned button {
    position: static;
    width: 120px;
    height: 43px;
    /* left: calc(50% - 45px/2); */
    top: 15.5px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
    flex: none;
    background: #005E60;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    margin-left: 21px;
    /* background: #005E60; */
}

.assigned-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 13px;
    position: absolute;
    left: 8.07%;
    right: 59.64%;
    top: 6.35%;
    bottom: 83.49%;
    background: #3F8484;
    border-radius: 6.55352px;
    width: 124px;
    height: 32px;
}

.assigned-header h6 {
    position: static;
    width: 52px;
    height: 14px;
    left: 36px;
    top: 9px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 14px;
}

.circle {
    position: absolute;
    left: 8.07%;
    right: 86.72%;
    top: 38.41%;
    bottom: 55.24%;
    background: #3F8484;
    border-radius: 100%;
    width: 20px;
    height: 20px;
}


.address-1 {
    position: absolute;
    left: 17.19%;
    right: 35.94%;
    top: 33.41%;
    bottom: 55.24%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #4F4F4F;
    width: 200px;
    height: 17px;
    text-transform: capitalize;
}

.border-line {
    position: absolute;
    left: 6.85%;
    right: 81.35%;
    top: 52.34%;
    bottom: 52.06%;
    opacity: 0.8;
    border: 0.655352px dashed #3F8484;
    transform: matrix(0.02, 1, -1, 0.02, 0, 0);
    width: 30px;
}

.circle-2 {
    position: absolute;
    left: 8.07%;
    right: 86.72%;
    top: 59.50%;
    bottom: 33.97%;
    background: #3F8484;
    border-radius: 100%;
    width: 20px;
    height: 20px;
}

.address-2 {
    position: absolute;
    left: 17.19%;
    right: 42.88%;
    top: 54.68%;
    bottom: 55.24%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #4F4F4F;
    width: 180px;
    height: 17px;
    text-transform: capitalize;
}

.trip-image {
    position: fixed;
    width: calc(50% - 21px);
    height: 100%;
    right: 0px;
    top: 0px;
    z-index: -1;
    background: #FAFAFA;
}

.trip-image img {
    position: absolute;
    left: 40.17%;
    right: 20.4%;
    top: 40.72%;
    bottom: 48.28%;
}

.cab-container {
    position: relative;
    margin-left: 8%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    top: 76%;
}

.accept-button {
    position: relative;
    background: #005E60;
    border: 1px solid #005E60;
    border-radius: 10px;
    height: 48px;
    width: 160px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    min-width: 45%;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;

}

.accept-button:hover {
    background: #3F8484;
    border: 1px solid #3F8484;
}

.decline-button {
    position: relative;
    background: #FAFAFA;
    border: 1px solid #005E60;
    border-radius: 10px;
    height: 48px;
    width: 160px;
    min-width: 45%;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #005E60;
    cursor: pointer;
}

.decline-button:hover {
    background: #3F8484;
    border: 1px solid #3F8484;
    color: #FFFFFF;
}


@media only screen and (min-width:200px)and (max-width: 539px) {
    .background {
        /* Remember to change this position to relative */
        position: relative;
        width: auto;
        top: 0px;
        bottom: 90px;
        left: 5px;
        right: 5px;
        margin-bottom: 22px;
        Height: 280px;
        background: #FAFAFA;
        border-radius: 5px;
    }
    
    .circle-4 {
        position: absolute;
        left: 44.44%;
        right: 44.44%;
        top: 25.44%;
        bottom: 44.44%;
        color: #005E60;
        width: 15px;
        height: 15px;
    }
    
    .button-assigned {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 30px;
        gap: 10px;
        position: fixed;
        width: 120px;
        height: 32px;
        left: calc(50% - 120px/2 - 107px);
        /* margin: 0px 21px; */
        top: 207px;
        /* background: #005E60; */
        /* border-radius: 10px; */
    }
    
    .button-assigned button {
        position: static;
        width: 91px;
        height: 32px;
        
        top: 15.5px;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #FFFFFF;
        flex: none;
        background: #005E60;
        order: 0;
        flex-grow: 0;
        margin: 0px 10px;
        cursor: pointer;
        border-radius: 10px;
        border: none;
        margin-left: 21px;
        /* background: #005E60; */
    }
    
    .assigned-header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px 13px;
        position: absolute;
        left: 8.07%;
        right: 59.64%;
        top: 6.35%;
        bottom: 83.49%;
        background: #3F8484;
        border-radius: 6.55352px;
        width: 124px;
        height: 32px;
    }
    
    .assigned-header h6 {
        position: static;
        width: 52px;
        height: 14px;
        left: 36px;
        top: 9px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 14px;
    }
    
    .circle {
        position: absolute;
        left: 8.07%;
        right: 86.72%;
        top: 38.41%;
        bottom: 55.24%;
        background: #3F8484;
        border-radius: 100%;
        width: 20px;
        height: 20px;
    }
    
    .trip-id {
        position: absolute;
        left: 8.07%;
        right: 60.94%;
        top: 16.20%;
        bottom: 69.84%;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 17px;
        color: #4F4F4F;
        width: 119px;
        height: 17px;
    }
    
    .address-1 {
        position: absolute;
        left: 17.19%;
        right: 35.94%;
        top: 33.41%;
        bottom: 55.24%;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #4F4F4F;
        width: 200px;
        height: 17px;
        text-transform: capitalize;
    }
    
    .border-line {
        position: absolute;
        left: 6.85%;
        right: 81.35%;
        top: 52.34%;
        bottom: 52.06%;
        opacity: 0.8;
        border: 0.655352px dashed #3F8484;
        transform: matrix(0.02, 1, -1, 0.02, 0, 0);
        width: 30px;
    }
    
    .circle-2 {
        position: absolute;
        left: 8.07%;
        right: 86.72%;
        top: 59.50%;
        bottom: 33.97%;
        background: #3F8484;
        border-radius: 100%;
        width: 20px;
        height: 20px;
    }
    
    .address-2 {
        position: absolute;
        left: 17.19%;
        right: 42.88%;
        top: 54.68%;
        bottom: 55.24%;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #4F4F4F;
        width: 180px;
        height: 17px;
        text-transform: capitalize;
    }
    
    .trip-image {
        position: fixed;
        width: 620px;
        height: 100%;
        left: 657px;
        top: 0px;
        background: none;
    }
    
    .trip-image img {
        position: absolute;
        left: 40.17%;
        right: 20.4%;
        top: 40.72%;
        bottom: 48.28%;
    }
    
    .flex-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 200px;
        background: #4F4F4F;
    }
    .accept-button {
        position: absolute;
        /* left: 8.07%;
        right: 0%;
        top: 76%;
        bottom: 0%; */
        background: #005E60;
        border: 1px solid #005E60;
        border-radius: 10px;
        height: 48px;
        width: calc(50% - 45px);
        padding-right: 5px;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        /* line-height: 12px; */
        text-align: center;
        color: #FFFFFF;
        cursor: pointer;
    
    }

    
    .accept-button:hover {
        background: #3F8484;
        border: 1px solid #3F8484;
    }
    
    .decline-button {
        position: absolute;
        /* left: 60%; */
        /* right: 8.07%;
        top: 76%;
        bottom: 0%; */
        background: #FAFAFA;
        border: 1px solid #005E60;
        border-radius: 10px;
        height: 48px;
        width: calc(50% - 45px);
        padding-left: 5px;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        /* line-height: 12px; */
        text-align: center;
        color: #005E60;
        cursor: pointer;
    }
    
    .decline-button:hover {
        background: #005E60;
        border: 1px solid #005E60;
        color: #FFFFFF;
    }
}

@media only screen and (min-width: 540px) and (max-width: 820px) {
    .background {
        /* Remember to change this position to relative */
        position: relative;
        width: auto;
        top: 301px;
        bottom: 90px;
        left: 5px;
        right: 5px;
        margin-bottom: 22px;
        Height: 280px;
        background: #FAFAFA;
        border-radius: 5px;
    }
    
    .circle-4 {
        position: absolute;
        left: 30.44%;
        right: 44.44%;
        top: 25.44%;
        bottom: 44.44%;
        color: #005E60;
        width: 15px;
        height: 15px;
    }
    
    .button-assigned {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 30px;
        gap: 10px;
        position: fixed;
        width: 120px;
        height: 32px;
        left: calc(50% - 120px/2 - 107px);
        /* margin: 0px 21px; */
        top: 207px;
        /* background: #005E60; */
        /* border-radius: 10px; */
    }
    
    .button-assigned button {
        position: static;
        width: 91px;
        height: 32px;
        
        top: 15.5px;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #FFFFFF;
        flex: none;
        background: #005E60;
        order: 0;
        flex-grow: 0;
        margin: 0px 10px;
        cursor: pointer;
        border-radius: 10px;
        border: none;
        margin-left: 21px;
        /* background: #005E60; */
    }
    
    .assigned-header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px 13px;
        position: absolute;
        left: 8.07%;
        right: 59.64%;
        top: 6.35%;
        bottom: 83.49%;
        background: #3F8484;
        border-radius: 6.55352px;
        width: 124px;
        height: 32px;
    }
    
    .assigned-header h6 {
        position: static;
        width: 52px;
        height: 14px;
        left: 36px;
        top: 9px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 14px;
    }
    
    .circle {
        position: absolute;
        left: 8.07%;
        right: 86.72%;
        top: 38.41%;
        bottom: 55.24%;
        background: #3F8484;
        border-radius: 100%;
        width: 20px;
        height: 20px;
    }
    
    .trip-id {
        position: absolute;
        left: 8.07%;
        right: 60.94%;
        top: 16.20%;
        bottom: 69.84%;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 17px;
        color: #4F4F4F;
        width: 119px;
        height: 17px;
    }
    
    .address-1 {
        position: absolute;
        left: 17.19%;
        right: 35.94%;
        top: 33.41%;
        bottom: 55.24%;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #4F4F4F;
        width: 200px;
        height: 17px;
        text-transform: capitalize;
    }
    
    .border-line {
        position: absolute;
        left: 6.85%;
        right: 81.35%;
        top: 52.34%;
        bottom: 52.06%;
        opacity: 0.8;
        border: 0.655352px dashed #3F8484;
        transform: matrix(0.02, 1, -1, 0.02, 0, 0);
        width: 30px;
    }
    
    .circle-2 {
        position: absolute;
        left: 8.07%;
        right: 86.72%;
        top: 59.50%;
        bottom: 33.97%;
        background: #3F8484;
        border-radius: 100%;
        width: 20px;
        height: 20px;
    }
    
    .address-2 {
        position: absolute;
        left: 17.19%;
        right: 42.88%;
        top: 54.68%;
        bottom: 55.24%;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #4F4F4F;
        width: 180px;
        height: 17px;
        text-transform: capitalize;
    }
    
    .trip-image {
        position: none;
        width: none;
        height: none;
        left: none;
        top: none;
        background: none;
    }
    
    .trip-image img {
        position: none;
        left: none;
        right: none;
        top: none;
        bottom: none;
    }
    
    .flex-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 200px;
        background: #4F4F4F;
    }
    .accept-button {
        position: absolute;
        /* left: 8.07%;
        right: 0%;
        top: 76%;
        bottom: 0%; */
        background: #005E60;
        border: 1px solid #005E60;
        border-radius: 10px;
        height: 48px;
        width: calc(50% - 85px);
        padding-right: 15px;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        /* line-height: 12px; */
        text-align: center;
        color: #FFFFFF;
        cursor: pointer;
    
    }

    
    .accept-button:hover {
        background: #3F8484;
        border: 1px solid #3F8484;
    }
    
    .decline-button {
        position: absolute;
        /* left: 60%; */
        /* right: 8.07%;
        top: 76%;
        bottom: 0%; */
        background: #FAFAFA;
        border: 1px solid #005E60;
        border-radius: 10px;
        height: 48px;
        width: calc(50% - 85px);
        padding-left: 15px;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        /* line-height: 12px; */
        text-align: center;
        color: #005E60;
        cursor: pointer;
    }
    
    .decline-button:hover {
        background: #005E60;
        border: 1px solid #005E60;
        color: #FFFFFF;
    }
}