.container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 5% 5% 1% 5%;
    height: 100vh;
    background: #FAFAFA;
}

.title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #333333;
    margin: 0;
}

.sub-title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #333333;
    margin: 0;
    margin-top: 1%;
}

.border-line {
    border: 0.5px solid rgba(63, 132, 132, 0.2);
    transform: rotate(0deg);
    margin-top: 3%;
    margin-bottom: 5%;
}

.mt-3 {
    margin-top: 3%;
}