.trip-header {
  position: relative;
  width: 58px;
  line-height: 28px;
  left: 120px;
  top: 30px;
  color: #000000;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  margin: 0px 21px;
}

.flex-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 22px;
  height: 70px;
  width: 42vw;
  top: 74px;
}

.details {
  font-weight: 300;
  font-size: 14px;
  line-height: 2px;
  color: #000000;
  /* width: 100px; */
  margin: 0px 21px;
  height: 17px;
  text-transform: capitalize;
}

.details-2 {
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
}

.details-3 {
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
}

.flex-container-2 {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  left: 120px;
  height: 43px;
  width: calc(50% - 120px - 44px);
  top: 129px;
  background: #fafafa;
  border-radius: 10px;
  margin-left: 21px;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding-left: 0% !important;
}

.flex-container-2 li div {
  /* margin: 0px 0px; */
  display: flex;
  flex-direction: row;
  color: #333333;
  font-weight: 400;
  font-size: 10px;
  font-style: normal;
  line-height: 12px;
  text-decoration: none;
  cursor: pointer;
  /* margin: 0px 30px; */
}

.trip-status {
  width: 200px;
}

.API-Response {
  position: relative;
  margin-left: 170px;
  margin-top: 100px;
}

.trip-list-container {
  /* position: relative; */
  /* min-height: 370px; */
  height: 62%;
  min-width: 40vw;
  left: 0px;
  position: absolute;
  margin: 0px 141px;
  margin-top: 94px;
  margin-bottom: 70px;
  /* background: blue; */
  border-radius: 10px;
  overflow: auto;
}

.pagination-container {
  /* position: relative; */
  /* min-height: 370px; */
  min-width: 31vw;
  left: 0px;
  position: relative;
  margin: 0px 141px;
  /* background: blue; */
  top: 75vh;
  width: 41%;
}

.activeView {
  justify-content: center;
  align-items: center;
  /* padding: 10px 30px; */
  height: 43px;
  /* left: 135px; */
  /* margin: 0px 21px; */
  /* top: 145px; */
  /* background: #005E60; */
  /* border-radius: 10px; */
}

.unsetbutton {
  border: unset;
  background: unset;
  font-weight: 400;
  font-size: 10px;
  font-style: normal;
  line-height: 12px;
  text-decoration: none;
  cursor: pointer;
}

.activeView > button {
  position: static;
  width: 120px;
  height: 43px;
  /* left: calc(50% - 45px/2); */
  top: 15.5px;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 12px !important;
  color: #ffffff;
  flex: none;
  background: #005e60 !important;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
  cursor: pointer;
  border-radius: 10px;
  border: none !important;
  /* margin-left: 21px; */
  /* background: #005E60; */
}

.trip-status-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  top: 63px;
  background: #fafafa;
  border-radius: 10px;
  margin-left: 135px;
  width: 40.5vw;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding-left: 0% !important;
}

.each-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 33%;
}

.heading-container {
  display: flex;
  flex-direction: row;
  position: relative;
}

.heading-container-left {
  display: flex;
  flex-direction: row;
  position: relative;
  min-width: 50%;
}

.heading-container-right {
  display: flex;
  flex-direction: row;
  position: relative;
  min-width: 50%;
  padding-left: 10%;
  margin-top: 2%;
  height: 5vh;
}

.search-field {
  display: flex;
  flex-direction: row;
  min-width: 60%;
  background: rgba(63, 132, 132, 0.1);
  border-radius: 10px;
  color: rgba(63, 132, 132, 0.8);
  align-items: center;
  padding-left: 2%;
  height: 100%;
  z-index: 10;
  /* border-color: rgba(63, 132, 132, 0.1); */
}

.search {
  min-width: 90%;
  background: rgba(63, 132, 132, 0);
  color: rgba(63, 132, 132, 0.8);
  border-color: rgba(63, 132, 132, 0);
  height: 100%;
  margin-left: 2%;
  font-style: normal;
}

.search::placeholder {
  font-style: normal;
  font-weight: 550;
  font-size: 10px;
  line-height: 12px;
}

::placeholder {
  color: rgba(63, 132, 132, 0.8);
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
}

.seach-field > svg {
  min-width: 10%;
}

.search:focus {
  /* outline: 1.3px solid #87ABA9; */
  outline: none;
}

@media only screen and (min-width: 200px) and (max-width: 539px) {
  .API-Response {
    position: none;
    margin-left: 25%;
    margin-top: 50%;
    align-content: center;
  }
  .trip-header {
    position: fixed;
    width: auto;
    height: 28px;
    left: 4px;
    top: 63px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;
  }

  .trip-list-container {
    /* position: relative; */
    /* min-height: 370px; */
    height: 60%;
    width: 100%;
    /* min-width: calc(50% - 120px - 44px); */
    left: 0px;
    position: fixed;
    padding: 0px 25px;
    margin: 0px 0px;
    margin-top: 285px;
    /* margin-bottom: 140px; */
    /* background: blue; */
    border-radius: 10px;
    overflow: auto;
  }

  .background {
    position: fixed;
    min-width: 539px;
    height: 263px;
    width: auto;
    /* top: 0px; */
    background: #005e60;
  }

  .trip-status {
    position: fixed;
    width: 117px;
    height: 19px;
    left: 25px;
    top: 113px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    text-transform: capitalize;
  }

  .distance {
    position: fixed;
    width: auto;
    height: 17px;
    right: 25px;
    top: 113px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    text-transform: capitalize;
  }

  .details {
    font-weight: 300;
    font-size: 14px;
    line-height: 2px;
    color: #000000;
    width: 100px;
    margin: 0px 21px;
    height: 17px;
  }

  .details-2 {
    position: fixed;
    width: auto;
    height: 24px;
    left: 25px;
    top: 135px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
  }

  .details-3 {
    position: fixed;
    width: auto;
    height: 24px;
    right: 25px;
    top: 135px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    text-transform: uppercase;
  }

  .flex-container-2 {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    left: 5px;
    right: 25px;
    height: 53px;
    width: auto;
    top: 180px;
    border-radius: 10px;
    list-style: none;
    background: #3f8484;
    flex-wrap: wrap;
  }

  .flex-container-2 li div {
    font-weight: 400;
    font-size: 10px;
    font-style: normal;
    line-height: 12px;
    text-decoration: none;
    cursor: pointer;
    color: #ffffff;
  }

  .search-field {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    background: rgba(63, 132, 132, 0.1);
    border-radius: 10px;
    color: rgba(63, 132, 132, 0.8);
    justify-content: space-between;
    align-items: center;
    /* margin-top: 2%; */
    padding-right: 6%;
    margin-left: 15px;
    margin-right: 12.5px;
    height: 60px;
  }

  .search {
    min-width: 80%;
    background: rgba(63, 132, 132, 0);
    color: rgba(63, 132, 132, 0.8);
    border-color: rgba(63, 132, 132, 0);
    height: 100%;
    margin-left: 5%;
    font-style: normal;
  }

  .search:focus {
    /* outline: 1.3px solid #87ABA9; */
    outline: none;
  }
}

@media only screen and (min-width: 540px) and (max-width: 820px) {
  .API-Response {
    position: none;
    margin-left: 33%;
    margin-top: 80%;
    align-content: center;
  }
  .trip-header {
    position: fixed;
    width: auto;
    height: 28px;
    left: 4px;
    top: 63px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;
  }

  .trip-list-container {
    /* position: relative; */
    /* min-height: 370px; */
    height: 62%;
    min-width: 40vw;
    left: 0px;
    position: fixed;
    margin: 0px 141px;
    margin-top: 220px;
    margin-bottom: 140px;
    /* background: blue; */
    border-radius: 10px;
    overflow: auto;
  }

  .background {
    position: fixed;
    min-width: 820px;
    height: 263px;
    width: auto;
    /* top: 0px; */
    background: #005e60;
  }

  .trip-status {
    position: fixed;
    width: 117px;
    height: 19px;
    left: 25px;
    top: 113px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    text-transform: capitalize;
  }

  .distance {
    position: fixed;
    width: auto;
    height: 17px;
    right: 25px;
    top: 113px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    text-transform: capitalize;
  }
  /* .flex-container {
        position: fixed;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        left: 120px;
        height: 70px;
        width: calc(50% - 414px/2);
        top: 74px;
    
    } */

  .details {
    font-weight: 300;
    font-size: 14px;
    line-height: 2px;
    color: #000000;
    width: 100px;
    margin: 0px 21px;
    height: 17px;
  }

  .details-2 {
    position: fixed;
    width: auto;
    height: 24px;
    left: 25px;
    top: 135px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
  }

  .details-3 {
    position: fixed;
    width: auto;
    height: 24px;
    right: 25px;
    top: 135px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    text-transform: uppercase;
  }

  .flex-container-2 {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    left: 5px;
    right: 25px;
    height: 53px;
    width: auto;
    top: 180px;
    border-radius: 10px;
    list-style: none;
    background: #3f8484;
    flex-wrap: wrap;
  }

  .flex-container-2 li div {
    font-weight: 400;
    font-size: 10px;
    font-style: normal;
    line-height: 12px;
    text-decoration: none;
    cursor: pointer;
    color: #ffffff;
  }
}
