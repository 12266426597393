.trip-header p {
    position: fixed;
    width: 58px;
    line-height: 28px;
    left: 120px;
    top: 30px;
    color: #000000;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    margin: 0px 21px;
}


