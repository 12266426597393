.home {
    position: absolute;
    height: 100%;
    width: 100%;
}

.container {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 50px 50px;
    width: 702px;
    height: 450px;
    left: calc(50% - 702px/2 - 0.5px);
    top: calc(50% - 450px/2 - 0.5px);
    background: #FFFFFF;
    border: 0.2px solid #87ABA9;
    border-radius: 10px;
}

.heading {
    position: relative;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 10px;
    line-height: 38px;
    text-align: center;
    color: #181818;
}

.subtitle {
    position: relative;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    margin-top: 5%;
    line-height: 120%;
    text-align: center;
    color: #000000;
}

.staffidbutton {
    align-items: center;
    margin-top: 10%;
    padding: 20px;
    gap: 20px;
    position: relative;
    width: 100%;
    /*left: calc(50% - 568px/2 - 0.5px);
    top: calc(50% - 68px/2 - 12.5px);*/
    background: #005E60;
    border-radius: 10px;
    color: #fff;
}

.phonennumberbutton {
    align-items: center;
    margin-top: 8%;
    padding: 20px;
    gap: 20px;
    height: 68px;
    position: relative;
    background: #005E60;
    border: 1px solid #005E60;
    border-radius: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #fff;
    cursor: pointer;
}

.phonennumberbutton:hover {
    background:#3F8484;
    border: 1px solid #3F8484;
}

.signup {
    position: relative;
    padding-top: 6%;
    padding-bottom: 4%;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #333333;
}

.signup>a {
    color: #005E60;
    font-weight: 700;
    text-decoration: unset;
}

.signup a:hover {
    color:#3F8484;
}

.mobilePixContainer {
    min-width: 100%;
    justify-content: center;
    align-items: center;
}

.mobilePix {
    display: none;
    width: 100%;
}

@media only screen and (min-width: 100px) and (max-width: 916px) {
    .mobilePix {
        display: block;
        padding-bottom: 3%;
    }

    .container {
        border: unset;
        top: calc(50% - 883px/2 - 0.5px);
        left: unset;
        top: unset;
        width: 100%;
        margin: 0;
        padding-left: 25px;
        padding-right: 25px;
    }

    .heading {
        position: relative;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 2px;
        line-height: 36px;
        text-align: center;
        color: #181818;
    }
    
    .subtitle {
        position: relative;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 120%;
        text-align: justify;
        color: #000000;
    }
    
    
    .phonennumberbutton {
        font-size: 12px;
    }
    
    .phonennumberbutton:hover {
        background:#3F8484;
        border: 1px solid #3F8484;
    }
    
    .signup {

        font-size: 12px;

    }

    
        
}

