.background {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: rgba(51, 51, 51, 0.7);
    backdrop-filter: blur(10px);
    z-index: 5;
    cursor: pointer;
}

.background-container {
    position: fixed;
    width: 450px;
    height: 450px;
    left: calc(50% - 450px/2 + 0.5px);
    top: calc(50% - 450px/2 + 0.5px);
    background: #FFFFFF;
    border-radius: 20px;
    z-index: 6;
}

.close-icon {
    position: absolute;
    left: 5.21%;
    right: 5.21%;
    top: 5.21%;
    bottom: 5.21%;
    width: 21.5px;
    height: 21.5px;
    cursor: pointer;
    z-index: 10;
}

.close {
    position: absolute;
    width: 36px;
    height: 17px;
    left: 9.89%;
    top: 5.60%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 8px;
    cursor: pointer;
    z-index: 10;
}


.trip-confirmation {
    position: absolute;
    width: 266px;
    height: 24px;
    left: calc(50% - 266px/2 + 0.5px);
    top: calc(50% - 150px/2 + 0.5px);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #181818;
}

.button-yes {
    position: absolute;
    left: 9%;
    right: 0%;
    top: 70%;
    bottom: 0%;
    background: #EE3737;
    border: 0.187317px solid #FF8282;
    box-sizing: border-box;
    border-radius: 9.36585px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #FFFFFF;
    width: 160px;
    height: 64px;
    cursor: pointer;

}

.button-no {
    position: absolute;
    left: 56%;
    right: 9%;
    top: 70%;
    bottom: 0%;
    border: 0.187317px solid #005E60;
    box-sizing: border-box;
    border-radius: 9.36585px;
    width: 160px;
    height: 64px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #005E60;
    background: #FFFFFF;
    cursor: pointer;
}

@media only screen and (min-width:200px)and (max-width: 539px) {
    .background {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        background: rgba(51, 51, 51, 0.7);
        backdrop-filter: blur(10px);
        z-index: 5;
        cursor: pointer;
    }

    .background-container {
        position: fixed;
        width: 220px;
        height: 450px;
        left: calc(50% - 220px/2 + 0.5px);
        top: calc(50% - 450px/2 + 0.5px);
        background: #FFFFFF;
        border-radius: 20px;
        z-index: 6;
    }

    .close-icon {
        position: absolute;
        left: 5.21%;
        right: 5.21%;
        top: 5.21%;
        bottom: 5.21%;
        width: 21.5px;
        height: 21.5px;
        cursor: pointer;
        z-index: 10;
    }

    .close {
        position: absolute;
        width: 36px;
        height: 17px;
        left: 13.89%;
        top: 5.60%;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0px 8px;
        cursor: pointer;
        z-index: 10;
    }


    .trip-confirmation {
        position: absolute;
        width: 150px;
        height: 24px;
        left: calc(50% - 150px/2 + 0.5px);
        top: calc(50% - 200px/2 + 0.5px);
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #181818;
    }

    .button-yes {
        position: absolute;
        left: 9%;
        right: 9%;
        top: 55%;
        bottom: 0%;
        background: #EE3737;
        border: 0.187317px solid #FF8282;
        box-sizing: border-box;
        border-radius: 9.36585px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #FFFFFF;
        width: auto;
        height: 64px;
        cursor: pointer;

    }

    .button-no {
        position: absolute;
        left: 9%;
        right: 9%;
        top: 75%;
        bottom: 0%;
        border: 0.187317px solid #005E60;
        box-sizing: border-box;
        border-radius: 9.36585px;
        width: auto;
        height: 64px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #005E60;
        background: #FFFFFF;
        cursor: pointer;
    }
}

@media only screen and (min-width:540px)and (max-width: 820px) {
    .background {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        background: rgba(51, 51, 51, 0.7);
        backdrop-filter: blur(10px);
        z-index: 5;
        cursor: pointer;
    }

    .background-container {
        position: fixed;
        width: 450px;
        height: 450px;
        left: calc(50% - 450px/2 + 0.5px);
        top: calc(50% - 450px/2 + 0.5px);
        background: #FFFFFF;
        border-radius: 20px;
        z-index: 6;
    }

    .close-icon {
        position: absolute;
        left: 5.21%;
        right: 5.21%;
        top: 5.21%;
        bottom: 5.21%;
        width: 21.5px;
        height: 21.5px;
        cursor: pointer;
        z-index: 10;
    }

    .close {
        position: absolute;
        width: 36px;
        height: 17px;
        left: 9.89%;
        top: 5.60%;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0px 8px;
        cursor: pointer;
        z-index: 10;
    }


    .trip-confirmation {
        position: absolute;
        width: auto;
        height: 24px;
        /* left: calc(50% - auto/2 + 0.5px); */
        top: calc(50% - 200px/2 + 0.5px);
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #181818;
    }

    .button-yes {
        position: absolute;
        left: 9%;
        right: 9%;
        top: 55%;
        bottom: 0%;
        background: #EE3737;
        border: 0.187317px solid #FF8282;
        box-sizing: border-box;
        border-radius: 9.36585px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #FFFFFF;
        width: auto;
        height: 64px;
        cursor: pointer;

    }

    .button-no {
        position: absolute;
        left: 9%;
        right: 9%;
        top: 75%;
        bottom: 0%;
        border: 0.187317px solid #005E60;
        box-sizing: border-box;
        border-radius: 9.36585px;
        width: auto;
        height: 64px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #005E60;
        background: #FFFFFF;
        cursor: pointer;
    }
}