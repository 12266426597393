.preloader {
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 100%;
    --sk-color: #005e60;
    --sk-size: 70px;
    width: 100vw;
    justify-content: center;
}

.preloader>* {
    align-self: center;
}

.mb-5 {
    margin-bottom: 5%;
}

.main-container {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 91vw;
    padding: 2% 2% 0% 10%;
}

.left-side {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 50%;
    margin-right: 2%;
    margin-left: 1.5%;
}

.greeting-box {
    position: relative;
    background: #005E60;
    border-radius: 10px;
    height: 130px;
    width: auto;
    display: flex;
    flex-direction: row;
    padding-left: 5%;
    padding-right: 3%;
}

.greeting-box-left-side {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 50%;
    color: #fff;
    align-self: center;
}

.greeting-box-left-side>p {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 3px;
    padding-top: 20%;
}

.greeting-box-left-side>h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 3px;
    padding-bottom: 15%;
}

.greeting-box-right-side {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    min-width: 50%;
}

.man {
    width: 35%;
}

.woman {
    width: 35%;
}

.earnings-message {
    position: relative;
    background: #005E60;
    border-radius: 10px;
    width: 100%;
    display: flex;
    height: 91px;
    flex-direction: row;
    margin-top: 5%;
    padding-top: 10%;
    padding-bottom: 9%;
    padding-left: 5%;
    padding-right: 3%;
}

.earnings-message-left-side {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 50%;
    color: #fff;
    align-self: center;
}

.earnings-message-left-side>p {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 1px;
}

.earnings-message-left-side>h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 1px;
}

.earnings-message-right-side {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-self: center;
    min-width: 50%;
}

.earnings-message-right-side>div {
    background: rgba(255, 255, 254, 0.8);
    padding: 3.7%;
    width: 40px;
    height: 40px;
    border-radius: 100%;
}

.earnings-message-right-side>div>svg {
    fill: #005E60;
    color: #fff;
}

.recent-text-heading {
    position: relative;
    width: 100%;
    display: flex;
    margin-top: 5%;
    flex-direction: row;
}

.recent-text-heading>h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}

.mb-5 {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 5px;
    color: #333333;
}

.exception {
    font-weight: 300;
}

.mini-trip-container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 4% 5%;
    background: #FAFAFA;
    margin-bottom: 2.5%;
    border-radius: 10px;
}

.mini-trip-container>h3 {
    /* padding: unset !important; */
    /* margin: unset !important; */
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 5px;
    color: #333333;
}

.mini-trip-container>p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 5px;
    color: #4F4F4F;

}

.right-side {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 50%;
    margin-left: 2%;
}

.search-profile-right-side-container {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 5%;
    margin-bottom: 15%;
}

.search-profile-right-side-container-main {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 5%;
    margin-bottom: 15%;
}

.search-field {
    display: flex;
    flex-direction: row;
    min-width: 60%;
    background: rgba(63, 132, 132, 0.1);
    border-radius: 10px;
    color: rgba(63, 132, 132, 0.8);
    align-items: center;
    padding-left: 2%;
    height: 100%;
    /* border-color: rgba(63, 132, 132, 0.1); */
}

.search {
    min-width: 90%;
    background: rgba(63, 132, 132, 0);
    color: rgba(63, 132, 132, 0.8);
    border-color: rgba(63, 132, 132, 0);
    height: 100%;
    margin-left: 5%;
    font-style: normal;
}

.search::placeholder {
    font-style: normal;
    font-weight: 550;
    font-size: 10px;
    line-height: 12px
}


::placeholder {
    color: rgba(63, 132, 132, 0.8);
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
}

.seach-field>svg {
    min-width: 10%;
}

.profile-actions-container {
    min-width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-left: 19%;
}

.profile-actions-container>svg {
    color: #005E60;
}

.profile-actions-container>img {
    /* width: 35%; */
    margin-left: 5%;
}

.learn-more-container {
    position: relative;
    display: flex;
    flex-direction: row;
    background: linear-gradient(180deg, #005E60 0%, #005E60 47.71%, #005E60 100%);
    border-radius: 10px;
    padding: 4% 6%;
    margin-top: 6%;
}

.learn-more-left {
    position: relative;
    display: flex;
    min-width: 50%;
    flex-direction: column;
}

.learn-more-left>h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 2px;
    color: #FFFFFE;
}

.learn-more-left>p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.03em;
    color: #FAFAFA;
}

.learn-more-left>button {
    background-color: #F1F1F1;
    color: #005E60;
    border: 0.187317px solid #005E60;
    border-radius: 9.36585px;
    margin-top: 10%;
    padding: 5% 5%;
    height: 42px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    width: 60%;
    cursor: pointer;
}

.learn-more-right {
    position: relative;
    display: flex;
    min-width: 50%;
    justify-content: start;
    margin-left: 19%;
}

.show-in-device {
    display: none;
}

.hide-in-device {
    /* display: unset; */
}

.picture-anchor {
    width: 40%;
}

.menu-profile-picture {
    width: 50px;
    height: 50px;
    position: relative;
    border-radius: 100%;
}

.name-container {
    display: relative;
    flex-direction: row;
    margin: -3% 0 2% 0;
}

.name-font {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 17px;
    color: #4F4F4F;
}

@media only screen and (max-width: 916px) {
    .show-in-device {
        display: flex;
        margin-top: 15%;
    }

    .name-container {
        position: relative;
        margin-left: 15px;
        margin-bottom: 3%;
    }

    .break-name {
        display: block;
        padding-top: 2%;
        font-weight: 600;
        font-size: 20px;
    }

    /* .hide-in-device {
        display: none;
    } */

    .main-container {
        flex-direction: column;
        /* padding: 2% 2% 3% 6%; */
        padding: 0;
        margin: 0;
    }

    /* .greeting-box {
        position: absolute;
        margin: 0;
        padding: 0;
        left: 20px;
        right: 20px;
    } */


    .left-side {
        min-width: 100%;
    }

    .right-side {
        min-width: 100%;
    }

    .learn-more-container {
        /* display: none; */
        margin-top: 1.5%;
        margin-bottom: 30%;
        margin-left: 15px;
        margin-right: 15px;
    }

    .learn-more-right {
        min-width: 50%;
        margin: 0;
        padding: 0;
    }

    .learn-more-right img {
        height: 110px;
        width: 110px;
        margin: 0;
        padding: 0;
        margin-top: 35px;
        justify-content: end;
        margin-left: 30px;
    }

    .learn-more-left>p {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
    }

    .learn-more-left>h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 16px;
        color: #FFFFFE;
    }

    .learn-more-left>button {
        font-size: 10px;
        width: 70%;
    }

    /* .learn-more-right {
        position: relative;
        display: flex;
        min-width: 50%;
        justify-content: start;
        margin-left: 19%;
    } */

    .greeting-box {
        margin-left: 15px;
        margin-right: 12.5px;
    }

    .greeting-box-left-side>p {
        line-height: 12px;
    }

    .greeting-box-left-side>h3 {
        line-height: 14px;
    }

    .greeting-box-right-side {
        display: none;
    }

    .mini-trip-container>p {

        font-size: 14px;
        line-height: 12px;
        color: #4F4F4F;
    }

    .search-profile-right-side-container {
        margin-bottom: 6%;
    }

    .search-field {
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
        background: rgba(63, 132, 132, 0.1);
        border-radius: 10px;
        color: rgba(63, 132, 132, 0.8);
        justify-content: space-between;
        align-items: center;
        /* margin-top: 2%; */
        padding-right: 6%;
        margin-left: 15px;
        margin-right: 12.5px;
        height: 60px;
    }

    .seach-field>svg {
        /* position: absolute;
        color: #FAFAFA;
        background: #3F8484!important;
        width: 45px;
        height: 45px; */
    }

    .search {
        min-width: 80%;
        background: rgba(63, 132, 132, 0);
        color: rgba(63, 132, 132, 0.8);
        border-color: rgba(63, 132, 132, 0);
        height: 100%;
        margin-left: 5%;
        font-style: normal;
    }

    .search:focus {
        /* outline: 1.3px solid #87ABA9; */
        outline: none;
    }

    .profile-actions-container {
        min-width: 35%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-left: 0;
        text-align: end;
    }

    .profile-actions-container>svg {
        color: #005E60;
    }

    .profile-actions-container>img {
        /* width: 35%; */
        margin-left: 5%;
    }


    .search-profile-right-side-container-main {
        display: none;
    }

    .earnings-message {
        display: none;
    }

    .mini-trip-container {
        margin-left: 15px;
        margin-right: 12.5px;
        margin-top: 4.5%;
        margin-bottom: 3%;
        border-radius: 5px;
    }

    .recent-text-heading h3 {
        margin-left: 15px;
        margin-top: 8%;
    }

    .mb-5 {
        margin-left: 15px;
        font-weight: 300;
    }

    .name-font {
        font-weight: 300;
    }




}