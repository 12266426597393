.pagination-container {
    display: flex;
    margin-top: 3%;
    position: relative;
    flex-direction: row;
  }
  
  .pagination-information {
    position: relative;
    min-width: 50%;
    align-self: center;
  }
  
  .pagination-information-text {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #000000;
  }
  
  .pagination-control {
    position: relative;
    min-width: 50%;
    text-align: end;
  }
  .pagination-control-container {
    position: relative;
    color: #005e60;
    display: flex;
    justify-content: flex-end;
    align-self: center;
    align-items: center;
    background-color: #fafafa;
    width: unset;
    display: flex;
    flex-direction: row;
  }
  

  .forward-arrow-container {
    display: flex;
    flex-direction: row;
    min-width: 5%;
  }

  .forward-arrow-container > svg, .backward-arrow-container > svg {
    fill: #005e60;
    top: 10%;
  }

  .backward-arrow-container {
    display: flex;
    flex-direction: row;
    min-width: 5%;
  }

  .navigations-container {
    display: flex;
    flex-direction: row;
    min-width: 90%;
    overflow-x: scroll;
    padding-bottom: 1%;
  }
  
  .active-pagination {
    border-radius: 10px;
    background-color: #005e60;
    padding: 2% 3%;
    color: #fff !important;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    margin: 0 3%;
    cursor: default;
  }
  .inactive-pagination {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    margin: 0 3%;
    top: 100%;
    cursor: pointer;
    align-self: center;
  }