.container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 5%;
    margin-top: 2.5%;
    /* margin-bottom: 2%; */
    background-color: rgba(250, 250, 250, 1);
    border-radius: 10px;
}

.top-section {
    position: relative;
    display: flex;
    flex-direction: row;
    padding-right: 2%;
}

.top-section-label {
    min-width: 20%;
    background-color: rgba(63, 132, 132, 1);
    border-radius: 5px;
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 1% 0;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
}

.top-section-trip-id {
    min-width: 80%;
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    align-content: center;
    align-self: center;
}

.middle-section {
    margin-top: 3%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-content: center;
    vertical-align: middle;
}

.middle-section-trip-start {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
}

.middle-section-trip-start-dot {
    width: 20px;
    border-radius: 100%;
    height: 20px;
    margin-right: 2%;
    background-color: rgba(63, 132, 132, 1);
}

.middle-section-trip-start-location {
    min-width: 60%;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-transform: capitalize;
    margin-left: 3%;
}

.container-button {
    display: flex;
    text-align: end;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    justify-items: end;
    justify-self: end;
    min-width: 30%;
}

.middle-section-accept-trip {
    background-color: rgba(63, 132, 132, 1);
    color: #fff;
    border: 0.187317px solid #005E60;
    border-radius: 9.36585px;
    /* padding: 10%; */
    height: 42px;
    width: 135px;
    border-color: rgba(63, 132, 132, 1);
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    margin-right: 4%;
    cursor: pointer;
}

.nav-link {
    color: #fff !important;
    text-decoration: unset;
}

.middle-section-decline-trip {
    background-color: #F1F1F1;
    color: #005E60;
    border: 0.187317px solid #005E60;
    border-radius: 9.36585px;
    padding: 10%;
    width: 135px;
    height: 42px;
    border-color: #F1F1F1;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    margin-right: 4%;
    cursor: pointer;
}

.middle-section-trip-border {
    position: relative;
    display: flex;
    flex-direction: row;
}

.middle-section-trip-border-line {
    position: relative;
    width: 34px;
    height: 130%;
    border-left: 1px dashed rgba(63, 132, 132, 1);
    bottom: 63%;
    margin-left: 2.2%;
}

@media only screen and (max-width: 769px) {
    .container {
        margin-top: 1.5%;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 3.5%;
        border-radius: 5px;
    }

    .middle-section-decline-trip {
        display: none;
    }

    .middle-section-accept-trip {
        display: none;
    }

    .middle-section-trip-start-dot {
        width: 16px;
        border-radius: 100%;
        height: 16px;
        margin-right: 2%;
    }

    .middle-section-trip-border-line {
        position: relative;
        margin: 5px 0px;
        height: 28px;
        border-left: 1px dashed rgba(63, 132, 132, 1);
        bottom: 63%;
        margin-left: 2.2%;
    }

    .top-section-label {
        min-width: 30%;
        margin-bottom: 4%;
        height: 25px;
        padding: 1% 0;
    }

    .top-section-trip-id {
        min-width: 60%;
        margin-left: 9%;
        justify-content: flex-end;
    }
}