.preloader {
    position: absolute;
    top: 350px;
    left: 400px;
    --sk-color: #005e60;
    --sk-size: 70px;
}

.container {
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 100vw;
    padding: 0 0 0 7%;
}

.menu {
    min-width: 25%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2%;
}

.menu-profile-picture {
    width: 35%;
    position: relative;
    border-radius: 50%;
}

.menu-user-name {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    margin: 0;
}

.menu-username {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #333333;
    margin: 0;
}

.menu-container {
    min-width: 50%;
}

.menu-item {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 38%;
}

.menu-item>svg {
    margin-right: 18%;
}

.active-menu>* {
    font-weight: 500 !important;
    color: #005E60 !important;
}

.menu-item-label,
.menu-item>svg {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #333333;
}

.menu-item:hover>* {
    cursor: pointer;
    font-weight: 500 !important;
    color: #005E60 !important;
}

.details {
    min-width: 75%;
}

.mt-2 {
    margin-top: 2rem;
}

.mt-1 {
    margin-top: 0.4rem;
}

.mobile-view {
    display: none;
}

@media only screen and (max-width: 769px) {
    .container {
        display: none;
    }

    .mobile-view {
        display: unset;
    }
}