.container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 3% 3% 2% 3%;
    background: #FAFAFA;
    height: 100vh;
}

.title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #333333;
}

.profile-picture-action {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profile-picture {
    width: 14%;
    position: relative;
    border-radius: 50%;
}

.change-profile-picture-button {
    color: #005E60;
    background: rgba(0, 94, 96, 0.2);
    border: 1px solid rgba(0, 94, 96, 0.04);
    border-radius: 10px;
    height: 57px;
    margin-left: 5%;
}

.border-line {
    border: 0.1px solid rgba(63, 132, 132, 0.2);
    transform: rotate(0deg);
    margin: 3% 0 5% 0;
}

.input-field-container {
    display: flex;
    flex-direction: row;
    min-width: 50%;
    background: rgba(63, 132, 132, 0.1);
    border-radius: 10px;
    color: #828282;
    align-items: center;
    padding-left: 2%;
    height: 64px;
}

.input-field {
    min-width: 90%;
    background: rgba(63, 132, 132, 0);
    color: #828282;
    border-color: rgba(63, 132, 132, 0);
    height: 100%;
    margin-left: 5%;
}

.errormessage {
    color: #FF0000;
    position: relative;
    padding-top: 3%;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
}

.space-around {
    margin: 4% 0
}

.button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.update-profile {
    margin-top: 5%;
    background: #005E60;
    border-radius: 10px;
    height: 64px;
    width: 205px;
    color: #FFFFFF;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border: 2px solid #005E60;
}

.input-field-dial-code {
    width: 5%;
    background: rgba(63, 132, 132, 0);
    color: #828282;
    border-color: rgba(63, 132, 132, 0);
    height: 100%;
    margin-left: 5%;
}

.input-field-phone {
    min-width: 70%;
    background: rgba(63, 132, 132, 0);
    color: #828282;
    border-color: rgba(63, 132, 132, 0);
    height: 100%;
}

.loadingbutton {
    position: relative;
    align-self: center;
    left: calc(50% - 108px/2 + 40.5px);
    --sk-color: #fff;
    --sk-size: 23px;
}