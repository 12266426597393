.preloader {
    position: relative;
    align-self: center;
    height: 200px;
    top: 55px;
    --sk-color: #005E60;
    --sk-size: 70px;
}

.container {
    box-sizing: border-box;
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 0px 50px 0 50px;
    width: 702px;
    left: calc(50% - 702px/2 - 0.5px);
    top: calc(50% - 565px/2 - 0.5px);
    background: #FFFFFF;
    border: 0.2px solid #87ABA9;
    border-radius: 10px;
}

.heading {
    position: relative;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    padding-top: 10px;
    margin-bottom: 5px;
    line-height: 38px;
    text-align: center;
    color: #181818;
}

.fullnamefield {
    align-items: flex-start;
    padding: 23px 22px;
    gap: 10px;
    margin-top: 5%;
    position: relative;
    font-style: normal;
    font-weight: 550;
    font-size: 16px;
    line-height: 19px;
    height: 68px;
    width: 100%;
    background: rgba(0, 94, 96, 0.04);
    border-radius: 10px;
    border-color: rgba(0, 94, 96, 0.04);
    border: 0.2px solid rgba(0, 94, 96, 0.04);
    color: #828282;
}

.fullnamefield:focus {
    outline: 1.3px solid #87ABA9;
}

.passwordfield {
    align-items: flex-start;
    padding: 23px 22px;
    gap: 10px;
    margin-top: 5%;
    position: relative;
    width: 100%;
    height: 68px;
    background: rgba(0, 94, 96, 0.04);
    border-radius: 10px;
    border-color: rgba(0, 94, 96, 0.04);
    border: 0.2px solid rgba(0, 94, 96, 0.04);
    color: #828282;
}

.passwordfield:focus {
    outline: 1.3px solid #87ABA9;
}

.phonesection {
    position: relative;
    display: flex;
    flex-direction: row;
}

.nationalityfield {
    align-items: flex-start;
    padding: 23px 22px;
    gap: 10px;
    margin-top: 5%;
    width: 35%;
    position: relative;
    background: rgba(0, 94, 96, 0.04);
    border-radius: 10px 0 0 10px;
    border-color: rgba(0, 94, 96, 0.04);
    border: 0.2px solid rgba(0, 94, 96, 0.04);
    color: #828282;
    font-style: normal;
    font-weight: 550;
    font-size: 16px;
    line-height: 19px;
}

.nationalityfield:focus {
    outline: 1.3px solid #87ABA9
}

.phonenumberfield {
    align-items: flex-start;
    padding: 23px 22px;
    gap: 10px;
    height: 68px;
    margin-top: 5%;
    position: relative;
    width: 70%;
    background: rgba(0, 94, 96, 0.04);
    border-radius: 0 10px 10px 0;
    border-color: rgba(0, 94, 96, 0.04);
    border: 0.2px solid rgba(0, 94, 96, 0.04);
    color: #828282;
    font-style: normal;
    font-weight: 550;
    font-size: 16px;
    line-height: 19px;
}

.phonenumberfield:focus {
    outline: 1.3px solid #87ABA9;
}

.passwordconfirmationfield {
    align-items: flex-start;
    padding: 23px 22px;
    height: 68px;
    gap: 10px;
    margin-top: 5%;
    position: relative;
    width: 100%;
    background: rgba(0, 94, 96, 0.04);
    border-radius: 10px;
    border-color: rgba(0, 94, 96, 0.04);
    border: 0.2px solid rgba(0, 94, 96, 0.04);
    color: #828282;
}

.passwordconfirmationfield:focus {
    outline: 1.3px solid #87ABA9;
}

.continue {
    align-items: center;
    margin-top: 5%;
    padding: 20px;
    gap: 20px;
    position: relative;
    width: 100%;
    background: #005E60;
    border: 1px solid #005E60;
    height: 68px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
}

.continue:hover {
    background:#3F8484;
    border: 1px solid #3F8484;
}

.signup {
    position: relative;
    padding-top: 4%;
    padding-bottom: 16px;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #333333;
}

.signup>* {
    color: #005E60;
    font-weight: 700;
    text-decoration: none;
}

.signup a:hover {
    color:#3F8484;
}

.errormessage {
    color: #FF0000;
    position: relative;
    padding-top: 3%;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
}

.loadingbutton {
    position: relative;
    align-self: center;
    left: calc(50% - 108px/2 + 40.5px);
    --sk-color: #fff;
    --sk-size: 23px;
}

.mobilePixContainer {
    min-width: 100%;
    justify-content: center;
    align-items: center;
}

.mobilePix {
    display: none;
    width: 100%;
}

@media only screen and (max-width: 916px) {
    .mobilePix {
        display: block;
        padding-bottom: 3%;
        padding-top: 10%;
    }

    .hideBackground {
        display: none;
    }

    .container {
        border: unset;
        top: calc(50% - 883px/2 - 0.5px);
        left: unset;
        top: unset;
        width: 100%;
        margin: 0;
        padding-left: 25px;
        padding-right: 25px;
    }

    .heading {
        position: relative;
        font-style: normal;
        font-weight: 700;
        width: 100%;
        font-size: 24px;
        margin-bottom: 5px;
        line-height: 36px;
        text-align: center;
        color: #181818;
    }
    
    .fullnamefield {
        align-items: center;
        padding: 23px 22px;
        gap: 10px;
        /* left: 25px; */
        /* right: 25px; */

        margin-top: 5%;
        position: relative;
        font-style: normal;
        font-weight: 550;
        font-size: 12px;
        line-height: 19px;
        height: 68px;
        width: 100%;
        background: rgba(0, 94, 96, 0.04);
        border-radius: 10px;
        border-color: rgba(20, 29, 29, 0.04);
        border: 0.2px solid rgba(0, 94, 96, 0.04);
        color: #828282;
    }
    
    .fullnamefield:focus {
        outline: 1.3px solid #87ABA9;
    }
    
    .passwordfield {
        align-items: center;
        padding: 23px 22px;
        gap: 10px;
        margin-top: 5%;
        position: relative;
        font-size: 12px;
        width: 100%;
        height: 68px;
        background: rgba(0, 94, 96, 0.04);
        border-radius: 10px;
        border-color: rgba(0, 94, 96, 0.04);
        border: 0.2px solid rgba(0, 94, 96, 0.04);
        color: #828282;
    }
    
    .passwordfield:focus {
        outline: 1.3px solid #87ABA9;
    }
    
    .phonesection {
        position: relative;
        display: flex;
        flex-direction: row;
    }
    
    .nationalityfield {
        align-items: center;
        padding: 23px 22px;
        gap: 10px;
        margin-top: 5%;
        width: 45%;
        position: relative;
        background: rgba(0, 94, 96, 0.04);
        border-radius: 10px 0 0 10px;
        border-color: rgba(0, 94, 96, 0.04);
        border: 0.2px solid rgba(0, 94, 96, 0.04);
        color: #828282;
        font-style: normal;
        font-weight: 550;
        font-size: 12px;
        line-height: 19px;
    }
    
    .nationalityfield:focus {
        outline: 1.3px solid #87ABA9
    }

    option:checked {
        Background:rgba(0, 94, 96, 0.04)
    }
    
    .phonenumberfield {
        align-items: flex-start;
        padding: 23px 22px;
        gap: 10px;
        height: 68px;
        margin-top: 5%;
        position: relative;
        width: 70%;
        background: rgba(0, 94, 96, 0.04);
        border-radius: 0 10px 10px 0;
        border-color: rgba(0, 94, 96, 0.04);
        border: 0.2px solid rgba(0, 94, 96, 0.04);
        color: #828282;
        font-style: normal;
        font-weight: 550;
        font-size: 12px;
        line-height: 19px;
    }
    
    .phonenumberfield:focus {
        outline: 1.3px solid #87ABA9;
    }
    
    .passwordconfirmationfield {
        align-items: flex-start;
        padding: 23px 22px;
        height: 68px;
        gap: 10px;
        margin-top: 5%;
        position: relative;
        width: 100%;
        font-size: 12px;
        background: rgba(0, 94, 96, 0.04);
        border-radius: 10px;
        border-color: rgba(0, 94, 96, 0.04);
        border: 0.2px solid rgba(0, 94, 96, 0.04);
        color: #828282;
    }
    
    .passwordconfirmationfield:focus {
        outline: 1.3px solid #87ABA9;
    }
    
    .continue {
        align-items: center;
        margin-top: 5%;
        padding: 20px;
        gap: 20px;
        position: relative;
        width: 100%;
        background: #005E60;
        border: 1px solid #005E60;
        height: 68px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 21px;
        border-radius: 10px;
        color: #fff;
        cursor: pointer;
    }
    
    .continue:hover {
        background:#3F8484;
        border: 1px solid #3F8484;
    }
    
    .signup {
        position: relative;
        padding-top: 4%;
        padding-bottom: 16px;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #333333;
    }
    
    .signup>* {
        color: #005E60;
        font-weight: 700;
        text-decoration: none;
    }
    
    .signup a:hover {
        color:#3F8484;
    }
    
    .errormessage {
        color: #FF0000;
        position: relative;
        padding-top: 3%;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
    }
    
    .loadingbutton {
        position: relative;
        align-self: center;
        left: calc(50% - 108px/2 + 40.5px);
        --sk-color: #fff;
        --sk-size: 23px;
    }

    .preloader {
        position: relative;
        align-items: center;
        height: 200px;
        top: 300px;
        left: 20px;
        --sk-color: #005E60;
        --sk-size: 70px;
    }

    
}